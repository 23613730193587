/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "5b8e60441a9a40fcaa72b7a95391d8e2",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "5b8e60441a9a40fcaa72b7a95391d8e2",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://enqcgqaxmbdxvbbsupogqtiez4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:2b92a1d4-2f0a-4b6d-a713-d0cb2637f608",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xu6NTVnha",
    "aws_user_pools_web_client_id": "6jre9i20sq97do3j43i51p7n37",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
