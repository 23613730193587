import React, { useState, useEffect } from 'react';
import { API,Analytics } from 'aws-amplify';
import { 
    createGoalFn as createGoalFnMutation,
    updateGoalFn as updateGoalFnMutation,
    deleteGoal as deleteGoalMutation,
    updateProjectFn as updateProjectFnMutation
} from './graphql/mutations';  
import {
    EuiButtonEmpty,
    EuiFormRow,
    EuiAvatar,
    EuiButton,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiSpacer,
    EuiModalFooter,
    EuiCallOut,
    EuiFieldText,
    EuiPanel,
    EuiText,
    EuiForm
} from '@elastic/eui';
import { 
    initialGoalFormState
} from './Constants';

export function CreateGoal({userData,goals,setGoals,doneCallback,doneCallbackParam,setSelectionValue,taskFormData,teamID,teamCalendarID,tourActions,tourReducerState,activeCalendar}) {
    const [goalFormData,setGoalFormData] = useState(initialGoalFormState);
    const [error,setError] = useState("");
  
    async function createGoal() {
      setError("");
      if (!goalFormData.name) return;
      goalFormData.calendarID = activeCalendar.value;
      if(teamID) {
        goalFormData.teamID = teamID;
        goalFormData.calendarID = teamCalendarID;
      }
      let goalOrder = goals.length;
      goalFormData.order = goalOrder;
      goalFormData.owner = userData.username;
      goalFormData.name = goalFormData.name.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,50);
      goalFormData.notes = goalFormData.notes.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,180);
      try {
        const apiData =  await API.graphql({ query: createGoalFnMutation, variables: { input: goalFormData } });
        apiData.data.createGoal = apiData.data.createGoalFn;
        setGoals([...goals, {"key":apiData.data.createGoal.id, "value": apiData.data.createGoal.id, "text": apiData.data.createGoal.name, "label": apiData.data.createGoal.name, "notes": apiData.data.createGoal.notes, "hidden": apiData.data.createGoal.hidden}]);
        setGoalFormData(initialGoalFormState);
        if(setSelectionValue) {
          taskFormData.goalID = apiData.data.createGoal.id;
          taskFormData.goalName = apiData.data.createGoal.name;
          setSelectionValue(apiData.data.createGoal.id);
        }
        Analytics.record({ 
          name: 'createGoal'
        });
      } catch(e) {
        setError("Error creating Goal.");
      }
      if(doneCallback) {
        doneCallback(doneCallbackParam);
      }
      if(tourReducerState.currentTourStep === 7) {
        tourActions.incrementStep();
      }
    }
    
    return (
      <div>
        <EuiPanel paddingSize="m" grow={false} style={{width: 300}}>
          <EuiText grow={false} textAlign='center'>
            <h3><EuiAvatar name="Create" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Create Goal</h3>
          </EuiText>
          <EuiSpacer size='s' />
          {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
            <p>
              {error}
            </p>
          </EuiCallOut><EuiSpacer size='s' /></> }
          <EuiForm>
            <EuiFormRow label="Name" display="columnCompressed">
              <EuiFieldText
                onChange={e => setGoalFormData({ ...goalFormData, 'name': e.target.value})}
                placeholder="Goal name"
                value={goalFormData.name}
              />
            </EuiFormRow>
            <EuiSpacer/>
            <EuiFormRow label="Notes" display="columnCompressed">
              <EuiFieldText
                onChange={e => setGoalFormData({ ...goalFormData, 'notes': e.target.value})}
                placeholder="Goal notes"
                value={goalFormData.notes}
              />
            </EuiFormRow>
            <EuiSpacer/>
            <EuiButton fill onClick={createGoal}>
              Create Goal
            </EuiButton>
          </EuiForm>
        </EuiPanel>
      </div>
    );
}
  
export function EditGoalModal({editGoal,setEditGoal,goals,setGoals,projects,setProjects}) {
  const [editGoalFormData,setEditGoalFormData] = useState({});
  const [error,setError] = useState("");

  useEffect(() => {
    if(editGoal) {
      setEditGoalFormData({id: editGoal.key, name: editGoal.text, notes: editGoal.notes, order: editGoal.order});
    }
  },[editGoal]);

  async function editGoalFn() {
    setError("");
    if (!editGoalFormData.id) return;
    try {
      let apiData = await API.graphql({ query: updateGoalFnMutation, variables: { input: {id: editGoalFormData.id, 'name': editGoalFormData.name, 'notes': editGoalFormData.notes, 'order': editGoalFormData.order}} });
      apiData.data.updateGoal = apiData.data.updateGoalFn;
      let newGoals = goals.filter(obj => obj.key !== editGoalFormData.id);
      newGoals.push({"key":apiData.data.updateGoal.id, "value": apiData.data.updateGoal.id, "text": apiData.data.updateGoal.name, "label": apiData.data.updateGoal.name, "notes": apiData.data.updateGoal.notes, "order": apiData.data.updateGoal.order, "hidden": apiData.data.updateGoal.hidden});
      newGoals = newGoals.sort((a, b) => {
        return a.order - b.order;
      });
      setGoals(newGoals);
      setEditGoalFormData({});
      let filteredProjects = projects.filter(obj => obj.goalid !== editGoalFormData.id);
      let editProjects = projects.filter(obj => obj.goalid === editGoalFormData.id);
      let updatedProjects = editProjects.map((elem,i)=>({...elem, goal: apiData.data.updateGoal}));
      let combinedProjects = ([...updatedProjects,...filteredProjects]);
      setProjects(combinedProjects);
      setEditGoal(null);
    } catch(e) {
      setError("Error editing Goal.");
    }
  }

  return (
      <>
      {editGoal &&
          <EuiModal
          style={{ width: 400 }}
          onClose={() => setEditGoal(null)}
          >
          <EuiModalHeader>
              <EuiModalHeaderTitle>
              <h1><EuiAvatar name="Edit" type="space" iconType="plus" size="m" color="#69707D" />&nbsp;Edit Goal</h1>
              </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
              <p>
                {error}
              </p>
            </EuiCallOut><EuiSpacer size='s' /></> }
              <EuiForm>
              <EuiFormRow label="Name">
                  <EuiFieldText
                  onChange={e => setEditGoalFormData({ ...editGoalFormData, 'name': e.target.value})}
                  placeholder="Goal name"
                  value={editGoalFormData.name}
                  />
              </EuiFormRow>
              <EuiSpacer/>
              <EuiFormRow label="Notes">
                  <EuiFieldText
                  onChange={e => setEditGoalFormData({ ...editGoalFormData, 'notes': e.target.value})}
                  placeholder="Goal notes"
                  value={editGoalFormData.notes}
                  />
              </EuiFormRow>
              </EuiForm>
              <EuiSpacer/>
          </EuiModalBody>
          <EuiModalFooter>
              <EuiButtonEmpty onClick={() => {
                setEditGoal(null);
              }}>Cancel</EuiButtonEmpty>

              <EuiButton 
              color="text"
              fill
              onClick={() => {
                editGoalFn();
              }}
              >
              Save
              </EuiButton>
          </EuiModalFooter>
          </EuiModal>
      }
      </>
  );
}

export function DeleteGoalModal({deleteGoal,setDeleteGoal,projects,setProjects,goals,setGoals}) {
  async function deleteGoalFn() {
    API.graphql({ query: deleteGoalMutation, variables: { input: { id: deleteGoal } }});
    let updatedGoals = goals.filter(obj => obj.key !== deleteGoal);
    setGoals(updatedGoals);
    let updatedProjects = projects.filter(obj => obj.goalid === deleteGoal);
    let notUpdatedProjects = projects.filter(obj => obj.goalid !== deleteGoal);
    for(let i in updatedProjects) {
      delete updatedProjects[i].goal;
      updatedProjects[i].goalid = "";
      API.graphql({ query: updateProjectFnMutation, variables: { input: {id: updatedProjects[i].key, 'goalID': ''}} }).catch((error)=>{});
    }
    setProjects([...notUpdatedProjects,...updatedProjects]);
  }

  return (
    <>
      {deleteGoal &&
        <EuiModal
          style={{ width: 400 }}
          onClose={() => setDeleteGoal(null)}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1><EuiAvatar name="Delete" type="space" iconType="plus" size="m" color="#BD271E" />&nbsp;Delete Goal</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiCallOut title="Note" color="warning" iconType="help">
              <p>
                Are you sure you want to delete the Goal?
              </p>
            </EuiCallOut>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={() => {
              setDeleteGoal(null);
            }}>Cancel</EuiButtonEmpty>

            <EuiButton 
              color="danger"
              fill
              onClick={() => {
                deleteGoalFn();
                setDeleteGoal(null);
              }}
            >
              Delete Goal
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      }
    </>
  );
}
  