import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify, { Analytics } from 'aws-amplify';
import config from './aws-exports';
import awsauth from './awsauth';
awsauth.redirectSignIn = `${window.location.origin}/`;
awsauth.redirectSignOut = `${window.location.origin}/`;
config.aws_appsync_graphqlEndpoint = "https://api.prod.timeblok.app/graphql";
config.oauth = awsauth;
Amplify.configure(config);
Analytics.autoTrack('session', {
  enable: true
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
