import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { API, Analytics } from 'aws-amplify';
import {
    listTaskByCalendarIDShareTeam
} from './graphql/queries'
import { 
    newOwnerTeam as newOwnerTeamMutation,
    joinTeam as joinTeamMutation,
    createTeamFn as createTeamFnMutation,
    addTeamAdminFn as addTeamAdminFnMutation,
    leaveTeam as leaveTeamMutation,
    deleteTeamFn as deleteTeamFnMutation,
    changeShareTeamFn as changeShareTeamFnMutation,
    createTeamGroupByDataFn as createTeamGroupByDataFnMutation,
    updateTeamGroupByDataFn as updateTeamGroupByDataFnMutation
} from './graphql/mutations'; 
import {
    EuiButtonEmpty,
    EuiFormRow,
    EuiAvatar,
    EuiButton,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiSpacer,
    EuiModalFooter,
    EuiCallOut,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
    EuiButtonIcon,
    EuiForm,
    EuiSwitch,
    EuiPageTemplate,
    EuiBasicTable,
    EuiProgress,
    EuiFilePicker,
    EuiSelect,
    useEuiTheme,
    logicals
} from '@elastic/eui'; 
import { css } from '@emotion/react';
import { ProjectSelect } from './Project';
import { usePapaParse } from 'react-papaparse';

function TeamMemberSelect({returnData, setReturnData, userData, userSettings, setUserSettings, noInitial, allowDelete}) {
    const [selectionValue, setSelectionValue] = useState(null);
    const [members,setMembers] = useState(JSON.parse(userSettings.team).teamMembers.filter(member => (member !== userData.username)).map(member => ({
        "value" : member,
        "key": member,
        "label": member.replaceAll("amazonfederate_",""),
        "text" : member.replaceAll("amazonfederate_","")
    })));

    async function removeMember() {
        let teamID = userSettings.teamID;
        await API.graphql({ query: leaveTeamMutation, variables: {'teamID': teamID, 'userID': selectionValue} });
        let membersToSet = members.filter(member => (member.value !== selectionValue));
        setMembers(membersToSet);
        let newTeamObject = JSON.parse(userSettings.team);
        let newTeamMembers = newTeamObject.teamMembers.filter(member => (member !== selectionValue));
        newTeamObject.teamMembers = newTeamMembers;
        console.log(newTeamObject);
        setUserSettings({...userSettings, 'team': JSON.stringify(newTeamObject)});
    }

    return(
        <>
        <EuiFlexGroup>
            <EuiFlexItem grow={9}>
            <EuiSelect
                placeholder='Select Team Member'
                options={members}
                value={selectionValue}
                hasNoInitialSelection={noInitial}
                onChange={(e) => {
                let newReturnData = {};
                newReturnData.teamMemberID = e.target.value;
                setReturnData(newReturnData);
                setSelectionValue(e.target.value);
                }}
            />
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
            {allowDelete && 
                <EuiButtonIcon aria-label="Delete Member" iconType="crossInACircleFilled" display="fill" size="m" color="danger"
                onClick={() => {
                    removeMember();
                }}
                />
            }
            </EuiFlexItem>
        </EuiFlexGroup>
        </>
    );
}

function UploadTeamData({activeTeam,teamGroupByData,setTeamGroupByData,userData,setMessage}) {
    const [showUploadModal, setShowUploadModal] = useState(0);
    const [csvData,setCSVData] = useState(null);
    const [aliasField,setAliasField] = useState("employee_login");
    const [tenureField,setTenureField] = useState("employee_tenure_group");
    const [levelField,setLevelField] = useState("job_level_name");
    const [managerField,setManagerField] = useState("supervisor_employee_name");
    const [lolField,setLolField] = useState("reports_to_level_7_employee_name");
    const [uploadProgress,setUploadProgress] = useState(0);
    const [uploadMax,setUploadMax] = useState(0);
    const [uploadInProgress,setUploadInProgress] = useState(false);
    const [error,setError] = useState("");
    const { readString } = usePapaParse();

    async function performImport() {
      setError("");
      setMessage("");
      setUploadInProgress(true);
      let newTeamGroupByData = {...teamGroupByData};
      setUploadMax(csvData.length);
      let error = 0;
      let success = 0;
      for(let i in csvData) {
        setUploadProgress(i);
        if(newTeamGroupByData["amazonfederate_"+csvData[i].alias]) {
            newTeamGroupByData["amazonfederate_"+csvData[i].alias].tenure = csvData[i].tenure;
            newTeamGroupByData["amazonfederate_"+csvData[i].alias].level = csvData[i].level;
            newTeamGroupByData["amazonfederate_"+csvData[i].alias].manager = csvData[i].manager; 
            newTeamGroupByData["amazonfederate_"+csvData[i].alias].leaderofleader = csvData[i].lol;
            try {
              await API.graphql({ query: updateTeamGroupByDataFnMutation, variables: { input: {id: teamGroupByData["amazonfederate_"+csvData[i].alias].id, tenure: csvData[i].tenure, level: csvData[i].level, manager: csvData[i].manager, leaderofleader: csvData[i].lol} }});
              success++;
            } catch(e) {
              error++;
            }
        } else {
          try {
            let results = await API.graphql({ query: createTeamGroupByDataFnMutation, variables: { input: {'userID': "amazonfederate_"+csvData[i].alias, 'teamID': activeTeam.id, tenure: csvData[i].tenure, level: csvData[i].level, manager: csvData[i].manager, leaderofleader: csvData[i].lol} }});
            newTeamGroupByData["amazonfederate_"+csvData[i].alias] = {
              'id': results.data.createTeamGroupByDataFn.id,
              'userID': "amazonfederate_"+csvData[i].alias, 
              'teamID': activeTeam.id, 
              'tenure': csvData[i].tenure, 
              'level': csvData[i].level, 
              'manager': csvData[i].manager,
              'leaderofleader': csvData[i].lol 
            };
            success++;
          } catch(e) {
            error++;
          }
        }
      }
      if(error > 0) {
        setError("There were "+error+ " error(s) while uploading your data.");
      } else {
        setMessage("Upload of "+success+" record(s) was successful.");
        setShowUploadModal(0);
      }
      setTeamGroupByData(newTeamGroupByData);
      setUploadInProgress(false);
    }

    const onChange = (files) => {
        const reader = new FileReader();
        
        reader.addEventListener('load', async (event) => {
        readString(event.target.result, {
            worker: true,
            complete: (results) => {
            let dataResults = [];
            let aliasFieldColNum = null;
            let tenureFieldColNum = null;
            let levelFieldColNum = null;
            let managerFieldColNum = null;
            let lolFieldColNum = null;
            let foundTitle = null;
            for(let i in results.data) {
                for(let y in results.data[i]) {
                switch (results.data[i][y]) {
                    case aliasField:
                    foundTitle = i;
                    aliasFieldColNum = y;
                    break;
                    case tenureField:
                    foundTitle = i;
                    tenureFieldColNum = y;
                    break;
                    case levelField:
                    foundTitle = i;
                    levelFieldColNum = y;
                    break;
                    case managerField:
                    foundTitle = i;
                    managerFieldColNum = y;
                    break;
                    case lolField:
                    foundTitle = i;
                    lolFieldColNum = y;
                    break;
                    default:
                    break;
                }
                }
                if(foundTitle && foundTitle !== i) {
                let row = {};
                if(aliasFieldColNum) {
                    if(results.data[i][aliasFieldColNum] !== 'null') {
                    row.alias = results.data[i][aliasFieldColNum];
                    }
                }
                if(tenureFieldColNum) {
                    if(results.data[i][tenureFieldColNum] !== 'null') {
                    row.tenure = results.data[i][tenureFieldColNum];
                    }
                }
                if(levelFieldColNum) {
                    if(results.data[i][levelFieldColNum] !== 'null') {
                    row.level = results.data[i][levelFieldColNum];
                    }
                }
                if(managerFieldColNum) {
                    if(results.data[i][managerFieldColNum] !== 'null') {
                    row.manager = results.data[i][managerFieldColNum];
                    }
                }
                if(lolFieldColNum) {
                    if(results.data[i][lolFieldColNum] !== 'null') {
                    row.lol = results.data[i][lolFieldColNum];
                    }
                }
                dataResults.push(row);
                }
            }
            setCSVData(dataResults);
            },
        });
        });


        reader.readAsText(files[0]);
    };

    return (
        <>
        <EuiButton 
            color="primary"
            fill
            iconType="indexOpen"
            onClick={() => {
            setShowUploadModal(1);
            }}
        >
            Upload Team Data
        </EuiButton>
        {showUploadModal!==0 &&
            <EuiModal
            style={{ width: 800 }}
            onClose={() => setShowUploadModal(0)}
            >
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                <h1><EuiAvatar name="UploadTeamData" type="space" iconType="indexEdit" size="m" color="#0071c2" />&nbsp;Upload Team Data</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              {error && <EuiCallOut title="Error" color="danger" iconType="stop">
                <p>
                  {error}
                </p>
              </EuiCallOut> }
                {showUploadModal===1 &&
                <>
                    <EuiCallOut title="Note" color="warning" iconType="help">
                    You can upload supplemental team data in csv format here. The uploaded file must be in the following format with column titles:<br/>
                    <br/>
                    aliasTitle,tenureTitle,levelTitle,managerTitle,leaderofleaderTitle<br/>
                    alias,tenure,level,manager,leaderofleader<br/>
                    <br/>

                    Column titles are customizable when uploading the data file. Just ensure that you input the column titles into the correct field textbox. If the data is not available, you can leave the field blank in the data file.<br/>
                    <br/>
                    The fields that are currently supported:
                    <ul>
                        <li>Tenure</li>
                        <li>Level</li>
                        <li>Manager</li>
                        <li>Leader of Leader</li>
                    </ul>
                    If your field is not listed above, please contact the TimeBlok team to discuss adding your use-case.
                    </EuiCallOut>
                    <EuiFormRow label="Alias field" display="columnCompressed">
                    <EuiFieldText
                        onChange={e => setAliasField(e.target.value)}
                        placeholder="Alias field"
                        value={aliasField}
                    />
                    </EuiFormRow>
                    <EuiFormRow label="Tenure field" display="columnCompressed">
                    <EuiFieldText
                        onChange={e => setTenureField(e.target.value)}
                        placeholder="Tenure field"
                        value={tenureField}
                    />
                    </EuiFormRow>
                    <EuiFormRow label="Level field" display="columnCompressed">
                    <EuiFieldText
                        onChange={e => setLevelField(e.target.value)}
                        placeholder="Level field"
                        value={levelField}
                    />
                    </EuiFormRow>
                    <EuiFormRow label="Manager field" display="columnCompressed">
                    <EuiFieldText
                        onChange={e => setManagerField(e.target.value)}
                        placeholder="Manager field"
                        value={managerField}
                    />
                    </EuiFormRow>
                    <EuiFormRow label="Leader of Leader field" display="columnCompressed">
                    <EuiFieldText
                        onChange={e => setLolField(e.target.value)}
                        placeholder="Leader of Leader field"
                        value={lolField}
                    />
                    </EuiFormRow>
                    <EuiFormRow label="Team Data File" display="columnCompressed">
                    <EuiFilePicker
                        id="filePicker"
                        initialPromptText="Select or drag and drop your team data file"
                        onChange={onChange}
                        display={'default'}
                        aria-label="Use aria labels when no actual label is in use"
                    />
                    </EuiFormRow>
                </>
                }
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButtonEmpty onClick={() => {
                setShowUploadModal(0);
                }}>Close</EuiButtonEmpty>
                <EuiButton 
                color="primary"
                isDisabled={!csvData}
                isLoading={uploadInProgress}
                fill
                onClick={performImport}
                >
                Upload
                </EuiButton><br/>
                <EuiProgress value={uploadProgress} max={uploadMax} size="s" />
            </EuiModalFooter>
            </EuiModal> 
        }
        </>
    );
}

function TeamMemberList({userSettings, setUserSettings, role, activeTeam, teamMembers, setTeamMembers}) {
    const [deleteMember,setDeleteMember] = useState(null);
    const [editMember,setEditMember] = useState(null);
    const { euiTheme } = useEuiTheme();

    async function removeMemberFn() {
        let teamID = userSettings.teamID;
        await API.graphql({ query: leaveTeamMutation, variables: {'teamID': teamID, 'userID': deleteMember} });
        let membersToSet = teamMembers.filter(member => (member.id !== deleteMember));
        setTeamMembers(membersToSet);
        let newTeamObject = JSON.parse(userSettings.team);
        let newTeamMembers = newTeamObject.teamMembers.filter(member => (member !== deleteMember));
        newTeamObject.teamMembers = newTeamMembers;
        console.log(newTeamObject);
        setUserSettings({...userSettings, 'team': JSON.stringify(newTeamObject)});
    }

    let actions = [
        {
        render: (item) => {
            return (
            <EuiFlexGroup style={{width: 100}}>
                <EuiFlexItem>
                <EuiButtonIcon
                    display="fill"
                    color="primary"
                    onClick={() => {
                    setEditMember(item);
                    }}
                    iconType="indexEdit"
                    aria-label="Edit"
                />
                </EuiFlexItem>
                <EuiFlexItem>
                <EuiButtonIcon
                    display="fill"
                    color="danger"
                    onClick={() => {
                    setDeleteMember(item.id);
                    }}
                    iconType="cross"
                    aria-label="Remove"
                />
                </EuiFlexItem>
            </EuiFlexGroup>
            );
        }
        }
    ];

    let columns = [
        {
        field: 'alias',
        name: 'Alias'
        },
        {
        field: 'admin',
        name: 'Admin'
        }
    ];

    if(role === "owner" || role === "admin") {
        columns.push(
        {
            field: 'tenure',
            name: 'Tenure'      
        }
        );
        columns.push(
        {
            field: 'level',
            name: 'Level'      
        }
        );
        columns.push(
        {
            field: 'manager',
            name: 'Manager'      
        }
        );
        columns.push(
        {
            field: 'leaderofleader',
            name: 'Leader of leader'      
        }
        );
    }

    if(role === "owner") {
        columns.push(
        {
            name: 'Actions',
            actions
        }
        );
    }

    return (
        <div>
        <EuiFlexGroup>
            <EuiFlexItem 
                css={css`
                    padding: calc(${euiTheme.size.s});
                    ${logicals['width']}: 1175px;
                `}>
            <EuiPanel paddingSize="m" grow={false}>
                <EuiText grow={false} textAlign='left'>
                <h3><EuiAvatar name="MemberList" type="space" iconType="list" size="m" color="#0071c2" />&nbsp;Member List</h3>
                </EuiText>
                <EuiSpacer />
                <EuiBasicTable
                tableCaption="Team Members"
                items={teamMembers}
                itemId="id"
                columns={columns}
                />
            </EuiPanel>
            </EuiFlexItem>
        </EuiFlexGroup>
        {deleteMember &&
            <EuiModal
            style={{ width: 400 }}
            onClose={() => setDeleteMember(null)}
            >
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                <h1><EuiAvatar name="DeleteMember" type="space" iconType="minus" size="m" color="#BD271E" />&nbsp;Delete Member</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiCallOut title="Note" color="warning" iconType="help">
                <p>
                    Are you sure you want to delete {deleteMember.replaceAll("amazonfederate_","")}?
                </p>
                </EuiCallOut>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButtonEmpty onClick={() => {
                setDeleteMember(null);
                }}>Cancel</EuiButtonEmpty>

                <EuiButton 
                color="danger"
                fill
                onClick={() => {
                    removeMemberFn();
                    setDeleteMember(null);
                }}
                >
                Delete
                </EuiButton>
            </EuiModalFooter>
            </EuiModal>     
        }
        {editMember &&
            <EuiModal
            style={{ width: 400 }}
            onClose={() => setEditMember(null)}
            >
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                <h1><EuiAvatar name="EditMember" type="space" iconType="indexEdit" size="m" color="#0071c2" />&nbsp;Edit Member</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiFormRow>
                <EuiSwitch
                    label="Team Admin"
                    checked={editMember.admin}
                    onChange={async (e) => {
                      editMember.admin = e.target.checked
                      let newEditMember = {...editMember};
                      setEditMember(newEditMember);
                      let teamAdminsObj = {};
                      for(let i in activeTeam.teamAdmins) {
                        teamAdminsObj[activeTeam.teamAdmins[i]] = 1;
                      }
                      if(e.target.checked) {
                        teamAdminsObj[editMember.id] = 1;
                      } else {
                        delete teamAdminsObj[editMember.id];
                      }
                      activeTeam.teamAdmins = Object.keys(teamAdminsObj);
                      try {
                        await API.graphql({ query: addTeamAdminFnMutation, variables: { teamID: activeTeam.id, 'teamAdmins': activeTeam.teamAdmins }});
                      } catch(e) {}
                    }}
                />
                </EuiFormRow>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButtonEmpty onClick={() => {
                setEditMember(null);
                }}>Close</EuiButtonEmpty>
            </EuiModalFooter>
            </EuiModal>     
        }
        </div>
    );
}

export function TeamPage({userData, userSettings, setUserSettings, teamProjects, setTeamProjects, setActiveMenu, teamEvents, setTeamEvents, activeCalendar, calendars, setCalendars, setTeamCalendars, teamGroupByData, setTeamGroupByData, logOut}) {
    const [createTeamFormData, setCreateTeamFormData] = useState({'id': '', 'password': ''});
    const [projectFormData,setProjectFormData] = useState({});
    const [returnData,setReturnData] = useState({});
    const [showTeamPanel,setShowTeamPanel] = useState(false);
    const [ownerLeaveTeam,setOwnerLeaveTeam] = useState(false);
    const [deleteTeam,setDeleteTeam] = useState(false);
    const [error,setError] = useState('');
    const [teamReportsLoading,setTeamReportsLoading] = useState(false);
    const [teamTasks,setTeamTasks] = useState([]);
    const [activeTeam,setActiveTeam] = useState({});
    const [teamMembers, setTeamMembers] = useState([]);
    const [role, setRole] = useState(false);
    const { euiTheme } = useEuiTheme();
  
    async function fetchTeamTasks(calendarID, type) {
      let teamTaskArr = [];
      let nextToken = 1;
      while(nextToken) {
        let variableObj = { 
          calendarIDshareTeam: calendarID + "" + type,
          limit: 1000
        };
        if(nextToken !== 1) {
          variableObj.nextToken = nextToken;
        }
        const apiData = await API.graphql({ query: listTaskByCalendarIDShareTeam, variables: variableObj});
        let _fetchedTasks = apiData.data.listTaskByCalendarIDShareTeam.items;
        nextToken = apiData.data.listTaskByCalendarIDShareTeam.nextToken;
        teamTaskArr = [...teamTaskArr,..._fetchedTasks];
      }
      return teamTaskArr;
    }
  
    async function fetchTeamData() {
      setTeamReportsLoading(false);
      setActiveMenu("teamReports");
    }
  
    const refreshTasks = useCallback(async (teamObj) => {
      let approvedTeamTasks = await fetchTeamTasks(teamObj.calendarID, 2);
      let notApprovedTeamTasks = await fetchTeamTasks(teamObj.calendarID, 1);
      setTeamTasks([...notApprovedTeamTasks,...approvedTeamTasks]);
    },[]);
  
    useEffect(()=>{
      if(userSettings.teamID) {
        setShowTeamPanel(true);
        let teamObj = JSON.parse(userSettings.team);
        if(!teamObj.teamAdmins) {
          teamObj.teamAdmins = [];
        }
        let teamMembersObj = teamObj.teamMembers.map((value, index) => {
          let returnObj = {
            "id": value, 
            "alias": value.replaceAll("amazonfederate_",""),
            "admin": teamObj.teamAdmins.some(elem => elem === value)
          };
          if(teamGroupByData[value]) {
            if(teamGroupByData[value].tenure) {
              returnObj.tenure = teamGroupByData[value].tenure;
            }
            if(teamGroupByData[value].level) {
              returnObj.level = teamGroupByData[value].level;
            }
            if(teamGroupByData[value].manager) {
              returnObj.manager = teamGroupByData[value].manager;
            }
            if(teamGroupByData[value].leaderofleader) {
              returnObj.leaderofleader = teamGroupByData[value].leaderofleader;
            }
          }
          return returnObj;
        });
        teamMembersObj = teamMembersObj.filter(elem => elem.id !== userSettings.id);
        
        setTeamMembers(teamMembersObj);
        setActiveTeam(teamObj);
        if(userSettings.id === teamObj.owner) {
          refreshTasks(teamObj);
          setRole("owner");
        } else if(teamObj.teamAdmins) {
          if(teamObj.teamAdmins.some(elem => elem === userSettings.id)) {
            setRole("admin");
          }
        }
      }
    },[userSettings.teamID, userSettings.id, userSettings.team, refreshTasks, teamGroupByData]);
  
    async function removeTeamState() {
      setRole(false);
      setShowTeamPanel(false);
      setUserSettings({...userSettings, 'teamID': '', 'team': ''});
      let newCalendars = calendars.filter((obj)=>!obj.teamid);
      setCalendars(newCalendars);
      setTeamCalendars([]);
    }
  
    async function deleteTeamAct() {
      setError("");
      removeTeamState();
      let teamID = userSettings.teamID;
      try {
        await API.graphql({ query: deleteTeamFnMutation, variables: {'teamID': teamID} });
      } catch(e) {
        setError("Error deleting team.");
      }
    }
  
    async function leaveTeam() {
      setError("");
      if(JSON.parse(userSettings.team).owner === userData.username) {
        if(JSON.parse(userSettings.team).teamMembers.length > 1) {
          setOwnerLeaveTeam(true);
        } else {
          setDeleteTeam(true);
        }
      } else {
        removeTeamState();
        let teamID = userSettings.teamID;
        try {
          await API.graphql({ query: leaveTeamMutation, variables: {'teamID': teamID, 'userID': userSettings.id} });
        } catch(e) {
          setError("Error leaving team.");
        }
      }
    }
  
    async function createTeam() {
      setError('');
      if (!createTeamFormData.id || !createTeamFormData.password) return;
      createTeamFormData.id = createTeamFormData.id.trim();
      try {
        await API.graphql({ query: createTeamFnMutation, variables: { input: createTeamFormData } });
      } catch(e) {
        if(e.errors.length) {
          setError('Error creating team. Another team with the same name might already exist.');
        }
      }
      Analytics.record({ 
        name: 'createTeam'
      });
      logOut();
    }
  
    async function joinTeam() {
      setError('');
      if (!createTeamFormData.id || !createTeamFormData.password) return;
      createTeamFormData.id = createTeamFormData.id.trim();
      const apiData =  await API.graphql({ query: joinTeamMutation, variables: {'teamID': createTeamFormData.id, 'password': createTeamFormData.password, 'userID': userSettings.id} });
      let jsonResponse = apiData.data.joinTeam;
      if(jsonResponse.status === 1) {
        setError('You are already part of this team.');
      } else if(jsonResponse.status === 2) {
        setError('Team does not exist.');
      } else if(jsonResponse.status === 3) {
        setError('Incorrect join phrase.');
      } else if(jsonResponse.status === 4) {
        setError('Unknown error joining team.');
      }
      if(jsonResponse.status === 0) {
        logOut();
        Analytics.record({ 
          name: 'joinTeam'
        });
      }
    }
    
    async function ownerLeaveFn() {
      setError("");
      if(returnData.teamMemberID) {
        setOwnerLeaveTeam(false);
        let teamID = userSettings.teamID;
        try {
          await API.graphql({ query: newOwnerTeamMutation, variables: {'teamID': teamID, 'userID': returnData.teamMemberID} });
          await API.graphql({ query: leaveTeamMutation, variables: {'teamID': teamID, 'userID': userSettings.id} });
        } catch(e) {
          setError("Could not leave team.");
        }
        removeTeamState();
        setReturnData({});
      }
    }
  
    async function changeShareTeam(oldTask, shareTeam) {
      setError("");
      let id = oldTask.id;
      try {
        await API.graphql({ query: changeShareTeamFnMutation, variables: {'taskID': id, 'shareTeam': shareTeam.toString()} });
        refreshTasks(activeTeam);
      } catch(e) {
        setError("Error with updating task.");
      }
    }
  
    const actions = [
      {
        render: (item) => {
          return (
            <EuiFlexGroup style={{width: 100}}>
              <EuiFlexItem>
                <EuiButtonIcon
                  display="fill"
                  color="primary"
                  onClick={() => {
                    changeShareTeam(item, 2);
                  }}
                  iconType="check"
                  aria-label="Approve"
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButtonIcon
                  display="fill"
                  color="danger"
                  onClick={() => {
                    changeShareTeam(item, 0);
                  }}
                  iconType="cross"
                  aria-label="Remove"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          );
        },
      }
    ];
  
    return(
      <>
       <EuiPageTemplate 
            template="empty"
            restrictWidth="100%">
        <div>
          {error && <EuiCallOut title="Message" color="primary" iconType="help">
            <p>
              {error}
            </p>
          </EuiCallOut> }
          <EuiFlexGroup>
            <EuiFlexItem  
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    padding-left: calc(${euiTheme.size.s});
                    padding-bottom: calc(${euiTheme.size.s});
                    ${logicals['width']}: 375px;
                `}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiPanel paddingSize="m" grow={false}>
                    <EuiText grow={false} textAlign='left'>
                      <h3><EuiAvatar name="CreateTeam" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Team Actions</h3>
                    </EuiText>
                    <EuiSpacer size='s' />
                    <EuiCallOut title="Note" color="warning" iconType="help">
                      <p>
                        Fill out the form to Create or Join a team. Note: If you create a team and you are already part of a team, you will leave your current team and join the new team you created. You can only be part of one team at a time. When joining a team, you will be logged off to update your authentication token.<br/>
                      </p>
                    </EuiCallOut>
                    <EuiSpacer/>
                    <EuiForm>
                      <EuiFormRow label="Name" display="columnCompressed"
                      >
                        <EuiFieldText
                          onChange={e => setCreateTeamFormData({ ...createTeamFormData, 'id': e.target.value})}
                          placeholder="Team name"
                          value={createTeamFormData.id}
                        />
                      </EuiFormRow>
                      <EuiSpacer/>
                      <EuiFormRow label="Join Phrase" display="columnCompressed">
                        <EuiFieldText
                          onChange={e => setCreateTeamFormData({ ...createTeamFormData, 'password': e.target.value})}
                          placeholder="Join Phrase"
                          value={createTeamFormData.password}
                        />
                      </EuiFormRow>
                      <EuiSpacer/>
                      <EuiButton 
                        iconType="checkInCircleFilled"
                        fill 
                        isDisabled={userSettings.teamID}
                        onClick={() => joinTeam()}>
                        Join Team
                      </EuiButton>
                      <EuiSpacer/>
                      <EuiButton 
                        iconType="plusInCircle"
                        fill
                        isDisabled={userSettings.teamID}
                        onClick={() => createTeam()}>
                          Create Team
                      </EuiButton>
                    </EuiForm>
                  </EuiPanel>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            {showTeamPanel &&
              <EuiFlexItem 
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    ${logicals['width']}: 375px;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'left' }}>
                    <h3><EuiAvatar name="TeamDetails" type="space" iconType="users" size="m" color="#0071c2" />&nbsp;Team Details</h3>
                  </EuiText>
                  <EuiSpacer />
                  <EuiText>
                    <p>
                      <b>Name:</b> {userSettings.teamID}<br />
                      <b>Owner:</b> {(JSON.parse(userSettings.team).owner).replaceAll("amazonfederate_", "")}<br />
                    </p>
                  </EuiText>
                  <EuiSpacer />
                  <EuiButton 
                    iconType="crossInACircleFilled"
                    fill 
                    onClick={leaveTeam}>
                    Leave Team
                  </EuiButton>
                </EuiPanel>
              </EuiFlexItem>
            }
            {(role === "owner" || role === "admin") &&
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    ${logicals['width']}: 375px;
                `}>
                <EuiPanel>
                  <EuiText>
                    <h3><EuiAvatar name="AdminActions" type="space" iconType="gear" size="m" color="#0071c2" />&nbsp;Admin Actions</h3>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiButton 
                    iconType="inspect"
                    fill 
                    isLoading={teamReportsLoading}
                    onClick={() => {
                      setTeamReportsLoading(true);
                      fetchTeamData();
                    }}>
                    Team Reports
                  </EuiButton>
                  <EuiSpacer/>
                  <UploadTeamData activeTeam={activeTeam} teamGroupByData={teamGroupByData} setTeamGroupByData={setTeamGroupByData} userData={userData} setMessage={setError}/>
                  <EuiSpacer/>
                  {role === "owner" && <>
                  <EuiText>
                    <h3><EuiAvatar name="OwnerActions" type="space" iconType="gear" size="m" color="#0071c2" />&nbsp;Owner Actions</h3>
                  </EuiText>
                  <EuiText style={{ maxWidth: 400 }}>
                    <p>
                      <b>Team Projects: </b>&nbsp;
                      <ProjectSelect taskFormData={projectFormData} userData={userData} projects={teamProjects} setProjects={setTeamProjects} teamID={userSettings.teamID} teamCalendarID={JSON.parse(userSettings.team).calendarID} allowEdit={true} allowDelete={true} activeCalendar={activeCalendar}/>
                    </p>
                  </EuiText>
                  </>}
                </EuiPanel>
              </EuiFlexItem>
            }
            </EuiFlexGroup>
            </div>
            <TeamMemberList userSettings={userSettings} setUserSettings={setUserSettings} role={role} activeTeam={activeTeam} teamMembers={teamMembers} setTeamMembers={setTeamMembers}/>
            {(role === "owner" || role === "admin") && <EuiFlexGroup>
            <EuiFlexItem
            css={css`
                padding: calc(${euiTheme.size.s});
                ${logicals['width']}: 1175px;
            `}>
              <EuiPanel paddingSize="m" grow={false}>
                <EuiText grow={false} textAlign='left'>
                  <h3><EuiAvatar name="Team Tasks" type="space" iconType="documents" size="m" color="#0071c2" />&nbsp;Team Tasks</h3>
                </EuiText>
                <EuiSpacer size='s' />
                <EuiBasicTable
                  tableCaption="Team Tasks"
                  items={teamTasks}
                  itemId="id"
                  columns={[
                    {
                      field: 'name',
                      name: 'Name'
                    },
                    {
                      field: 'owner',
                      name: 'Owner',
                      render: (owner) => (
                        owner.replaceAll("amazonfederate_", "")
                      )
                    },
                    {
                      field: 'startDateTime',
                      name: 'Start',
                      render: (date) => (
                        moment(date,moment.ISO_8601).format("YYYY-MM-DD hh:mm:ss A")
                      )
                    },
                    {
                      field: 'shareTeam',
                      name: 'Share to Team',
                      render: (shareTeam) => {
                        if(shareTeam === 2) {
                          return "Approved";
                        } else {
                          return "Awaiting Approval";
                        }
                      }
                    },
                    {
                      name: 'Approval',
                      actions
                    }
                  ]}
                />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>}
          {ownerLeaveTeam &&
            <EuiModal
              style={{ width: 400 }}
              onClose={() => setOwnerLeaveTeam(false)}
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  <h1><EuiAvatar name="OwnerLeave" type="space" iconType="minus" size="m" color="#BD271E" />&nbsp;Owner Leave Team</h1>
                </EuiModalHeaderTitle>
              </EuiModalHeader>
  
              <EuiModalBody>
                <EuiCallOut title="Note" color="warning" iconType="help">
                  <p>
                    Leaving a team while you are the owner requires that you select a new owner. If you are the last person on the team, the team will be deleted.
                  </p>
                </EuiCallOut>
                  <EuiForm>
                    <EuiFormRow label="New Owner">
                      <TeamMemberSelect returnData={returnData} setReturnData={setReturnData} userData={userData} userSettings={userSettings} setUserSettings={setUserSettings} noInitial={true}/>
                    </EuiFormRow>
                  </EuiForm>
                <EuiSpacer/>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => {
                  setOwnerLeaveTeam(false);
                }}>Cancel</EuiButtonEmpty>
  
                <EuiButton 
                  color="danger"
                  fill
                  onClick={() => {
                    ownerLeaveFn();
                  }}
                >
                  Leave Team
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>     
          }
          {deleteTeam &&
            <EuiModal
              style={{ width: 400 }}
              onClose={() => setDeleteTeam(null)}
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  <h1><EuiAvatar name="DeleteTeam" type="space" iconType="trash" size="m" color="#BD271E" />&nbsp;Delete Team</h1>
                </EuiModalHeaderTitle>
              </EuiModalHeader>
  
              <EuiModalBody>
                <EuiCallOut title="Note" color="warning" iconType="help">
                  <p>
                    Are you sure you want to delete the team?
                  </p>
                </EuiCallOut>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => {
                  setDeleteTeam(null);
                }}>Cancel</EuiButtonEmpty>
  
                <EuiButton 
                  color="danger"
                  fill
                  onClick={() => {
                    deleteTeamAct();
                    setDeleteTeam(null);
                  }}
                >
                  Delete Team
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>     
          }
        </EuiPageTemplate>
      </>
    );
}