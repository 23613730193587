/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStatsFn = /* GraphQL */ `
  query GetStatsFn($input: String) {
    getStatsFn(input: $input)
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      altNames
      userID
      type
      color
      goalID
      goal {
        id
        name
        owner
        order
        notes
        calendarID
        hidden
        createdAt
        updatedAt
        __typename
      }
      notes
      teamID
      teamMembers
      tags
      calendarID
      owner
      hidden
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          hidden
          createdAt
          updatedAt
          __typename
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        hidden
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      name
      owner
      order
      notes
      calendarID
      hidden
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        order
        notes
        calendarID
        hidden
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      startDateTime
      endDateTime
      duration
      status
      notificationEndStatus
      notificationStatus
      difficulty
      focus
      importance
      urgency
      blocker
      shareTeam
      timeSpent
      userID
      projectID
      projectTag
      project {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          hidden
          createdAt
          updatedAt
          __typename
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        hidden
        createdAt
        updatedAt
        __typename
      }
      reccuringTaskID
      teamID
      teamAdmins
      outlookInvite
      outlookUID
      outlookNotAccepted
      calendarID
      calendarIDgroup
      calendarIDuserID
      calendarIDshareTeam
      owner
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        startDateTime
        endDateTime
        duration
        status
        notificationEndStatus
        notificationStatus
        difficulty
        focus
        importance
        urgency
        blocker
        shareTeam
        timeSpent
        userID
        projectID
        projectTag
        project {
          id
          name
          altNames
          userID
          type
          color
          goalID
          goal {
            id
            name
            owner
            order
            notes
            calendarID
            hidden
            createdAt
            updatedAt
            __typename
          }
          notes
          teamID
          teamMembers
          tags
          calendarID
          owner
          hidden
          createdAt
          updatedAt
          __typename
        }
        reccuringTaskID
        teamID
        teamAdmins
        outlookInvite
        outlookUID
        outlookNotAccepted
        calendarID
        calendarIDgroup
        calendarIDuserID
        calendarIDshareTeam
        owner
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecurringTask = /* GraphQL */ `
  query GetRecurringTask($id: ID!) {
    getRecurringTask(id: $id) {
      id
      name
      description
      startDateTime
      duration
      userID
      projectID
      nextCreationTime
      recurringTaskType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRecurringTasks = /* GraphQL */ `
  query ListRecurringTasks(
    $filter: ModelRecurringTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        startDateTime
        duration
        userID
        projectID
        nextCreationTime
        recurringTaskType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phone
      ftmin
      fvmin
      emailnotify
      phonenotify
      webnotify
      calstarttime
      calendtime
      fcmToken
      calendarInvite
      importAutoCat
      importProjectRequired
      timeformat
      teamID
      firstVisit
      firstVisitPlace
      team {
        id
        password
        owner
        allowPersonalProjects
        teamMembers
        calendarID
        teamAdmins
        createdAt
        updatedAt
        __typename
      }
      outlookProjectSelection
      reportsDateRange
      selectedCalendarID
      hideprojects
      hidedefaultprojects
      calendarView
      workWeek
      calendarHeight
      calendarSteps
      calendarSlots
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        ftmin
        fvmin
        emailnotify
        phonenotify
        webnotify
        calstarttime
        calendtime
        fcmToken
        calendarInvite
        importAutoCat
        importProjectRequired
        timeformat
        teamID
        firstVisit
        firstVisitPlace
        team {
          id
          password
          owner
          allowPersonalProjects
          teamMembers
          calendarID
          teamAdmins
          createdAt
          updatedAt
          __typename
        }
        outlookProjectSelection
        reportsDateRange
        selectedCalendarID
        hideprojects
        hidedefaultprojects
        calendarView
        workWeek
        calendarHeight
        calendarSteps
        calendarSlots
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      password
      owner
      allowPersonalProjects
      teamMembers
      calendarID
      teamAdmins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        password
        owner
        allowPersonalProjects
        teamMembers
        calendarID
        teamAdmins
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamGroupByData = /* GraphQL */ `
  query GetTeamGroupByData($id: ID!) {
    getTeamGroupByData(id: $id) {
      id
      userID
      teamID
      tenure
      level
      manager
      leaderofleader
      teamAdmins
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeamGroupByData = /* GraphQL */ `
  query ListTeamGroupByData(
    $filter: ModelTeamGroupByDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamGroupByData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        teamID
        tenure
        level
        manager
        leaderofleader
        teamAdmins
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($id: ID!) {
    getCalendar(id: $id) {
      id
      group
      name
      description
      owner
      teamID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCalendars = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        description
        owner
        teamID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShareCalendar = /* GraphQL */ `
  query GetShareCalendar($id: ID!) {
    getShareCalendar(id: $id) {
      id
      calendarID
      calendar {
        id
        group
        name
        description
        owner
        teamID
        createdAt
        updatedAt
        __typename
      }
      recipient
      sender
      description
      owner
      teamID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShareCalendars = /* GraphQL */ `
  query ListShareCalendars(
    $filter: ModelShareCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        calendarID
        calendar {
          id
          group
          name
          description
          owner
          teamID
          createdAt
          updatedAt
          __typename
        }
        recipient
        sender
        description
        owner
        teamID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStats = /* GraphQL */ `
  query GetStats($id: ID!) {
    getStats(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStats = /* GraphQL */ `
  query ListStats(
    $filter: ModelStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOutlookDataRequest = /* GraphQL */ `
  query GetOutlookDataRequest($id: ID!) {
    getOutlookDataRequest(id: $id) {
      id
      start
      end
      mailbox
      status
      response
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOutlookDataRequests = /* GraphQL */ `
  query ListOutlookDataRequests(
    $filter: ModelOutlookDataRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutlookDataRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start
        end
        mailbox
        status
        response
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProjectsByUserID = /* GraphQL */ `
  query ListProjectsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          hidden
          createdAt
          updatedAt
          __typename
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        hidden
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProjectsByTeamID = /* GraphQL */ `
  query ListProjectsByTeamID(
    $teamID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByTeamID(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          hidden
          createdAt
          updatedAt
          __typename
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        hidden
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProjectsByOwner = /* GraphQL */ `
  query ListProjectsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          hidden
          createdAt
          updatedAt
          __typename
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        hidden
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listGoalsByOwner = /* GraphQL */ `
  query ListGoalsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoalsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        order
        notes
        calendarID
        hidden
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskByUserID = /* GraphQL */ `
  query ListTaskByUserID(
    $userID: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskByUserID(
      userID: $userID
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        startDateTime
        endDateTime
        duration
        status
        notificationEndStatus
        notificationStatus
        difficulty
        focus
        importance
        urgency
        blocker
        shareTeam
        timeSpent
        userID
        projectID
        projectTag
        project {
          id
          name
          altNames
          userID
          type
          color
          goalID
          goal {
            id
            name
            owner
            order
            notes
            calendarID
            hidden
            createdAt
            updatedAt
            __typename
          }
          notes
          teamID
          teamMembers
          tags
          calendarID
          owner
          hidden
          createdAt
          updatedAt
          __typename
        }
        reccuringTaskID
        teamID
        teamAdmins
        outlookInvite
        outlookUID
        outlookNotAccepted
        calendarID
        calendarIDgroup
        calendarIDuserID
        calendarIDshareTeam
        owner
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskByProject = /* GraphQL */ `
  query ListTaskByProject(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskByProject(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        startDateTime
        endDateTime
        duration
        status
        notificationEndStatus
        notificationStatus
        difficulty
        focus
        importance
        urgency
        blocker
        shareTeam
        timeSpent
        userID
        projectID
        projectTag
        project {
          id
          name
          altNames
          userID
          type
          color
          goalID
          goal {
            id
            name
            owner
            order
            notes
            calendarID
            hidden
            createdAt
            updatedAt
            __typename
          }
          notes
          teamID
          teamMembers
          tags
          calendarID
          owner
          hidden
          createdAt
          updatedAt
          __typename
        }
        reccuringTaskID
        teamID
        teamAdmins
        outlookInvite
        outlookUID
        outlookNotAccepted
        calendarID
        calendarIDgroup
        calendarIDuserID
        calendarIDshareTeam
        owner
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskByOutlookUID = /* GraphQL */ `
  query ListTaskByOutlookUID(
    $outlookUID: String!
    $calendarIDuserID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskByOutlookUID(
      outlookUID: $outlookUID
      calendarIDuserID: $calendarIDuserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        startDateTime
        endDateTime
        duration
        status
        notificationEndStatus
        notificationStatus
        difficulty
        focus
        importance
        urgency
        blocker
        shareTeam
        timeSpent
        userID
        projectID
        projectTag
        project {
          id
          name
          altNames
          userID
          type
          color
          goalID
          goal {
            id
            name
            owner
            order
            notes
            calendarID
            hidden
            createdAt
            updatedAt
            __typename
          }
          notes
          teamID
          teamMembers
          tags
          calendarID
          owner
          hidden
          createdAt
          updatedAt
          __typename
        }
        reccuringTaskID
        teamID
        teamAdmins
        outlookInvite
        outlookUID
        outlookNotAccepted
        calendarID
        calendarIDgroup
        calendarIDuserID
        calendarIDshareTeam
        owner
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskByCalendarID = /* GraphQL */ `
  query ListTaskByCalendarID(
    $calendarID: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskByCalendarID(
      calendarID: $calendarID
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        startDateTime
        endDateTime
        duration
        status
        notificationEndStatus
        notificationStatus
        difficulty
        focus
        importance
        urgency
        blocker
        shareTeam
        timeSpent
        userID
        projectID
        projectTag
        project {
          id
          name
          altNames
          userID
          type
          color
          goalID
          goal {
            id
            name
            owner
            order
            notes
            calendarID
            hidden
            createdAt
            updatedAt
            __typename
          }
          notes
          teamID
          teamMembers
          tags
          calendarID
          owner
          hidden
          createdAt
          updatedAt
          __typename
        }
        reccuringTaskID
        teamID
        teamAdmins
        outlookInvite
        outlookUID
        outlookNotAccepted
        calendarID
        calendarIDgroup
        calendarIDuserID
        calendarIDshareTeam
        owner
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskByCalendarIDuserID = /* GraphQL */ `
  query ListTaskByCalendarIDuserID(
    $calendarIDuserID: String!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskByCalendarIDuserID(
      calendarIDuserID: $calendarIDuserID
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        startDateTime
        endDateTime
        duration
        status
        notificationEndStatus
        notificationStatus
        difficulty
        focus
        importance
        urgency
        blocker
        shareTeam
        timeSpent
        userID
        projectID
        projectTag
        project {
          id
          name
          altNames
          userID
          type
          color
          goalID
          goal {
            id
            name
            owner
            order
            notes
            calendarID
            hidden
            createdAt
            updatedAt
            __typename
          }
          notes
          teamID
          teamMembers
          tags
          calendarID
          owner
          hidden
          createdAt
          updatedAt
          __typename
        }
        reccuringTaskID
        teamID
        teamAdmins
        outlookInvite
        outlookUID
        outlookNotAccepted
        calendarID
        calendarIDgroup
        calendarIDuserID
        calendarIDshareTeam
        owner
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskByCalendarIDShareTeam = /* GraphQL */ `
  query ListTaskByCalendarIDShareTeam(
    $calendarIDshareTeam: String!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskByCalendarIDShareTeam(
      calendarIDshareTeam: $calendarIDshareTeam
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        startDateTime
        endDateTime
        duration
        status
        notificationEndStatus
        notificationStatus
        difficulty
        focus
        importance
        urgency
        blocker
        shareTeam
        timeSpent
        userID
        projectID
        projectTag
        project {
          id
          name
          altNames
          userID
          type
          color
          goalID
          goal {
            id
            name
            owner
            order
            notes
            calendarID
            hidden
            createdAt
            updatedAt
            __typename
          }
          notes
          teamID
          teamMembers
          tags
          calendarID
          owner
          hidden
          createdAt
          updatedAt
          __typename
        }
        reccuringTaskID
        teamID
        teamAdmins
        outlookInvite
        outlookUID
        outlookNotAccepted
        calendarID
        calendarIDgroup
        calendarIDuserID
        calendarIDshareTeam
        owner
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRecurringTaskByUserID = /* GraphQL */ `
  query ListRecurringTaskByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringTaskByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        startDateTime
        duration
        userID
        projectID
        nextCreationTime
        recurringTaskType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsersByTeamID = /* GraphQL */ `
  query ListUsersByTeamID(
    $teamID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByTeamID(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phone
        ftmin
        fvmin
        emailnotify
        phonenotify
        webnotify
        calstarttime
        calendtime
        fcmToken
        calendarInvite
        importAutoCat
        importProjectRequired
        timeformat
        teamID
        firstVisit
        firstVisitPlace
        team {
          id
          password
          owner
          allowPersonalProjects
          teamMembers
          calendarID
          teamAdmins
          createdAt
          updatedAt
          __typename
        }
        outlookProjectSelection
        reportsDateRange
        selectedCalendarID
        hideprojects
        hidedefaultprojects
        calendarView
        workWeek
        calendarHeight
        calendarSteps
        calendarSlots
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTeamGroupByDataByUserID = /* GraphQL */ `
  query ListTeamGroupByDataByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamGroupByDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamGroupByDataByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        teamID
        tenure
        level
        manager
        leaderofleader
        teamAdmins
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTeamGroupByDataByTeamID = /* GraphQL */ `
  query ListTeamGroupByDataByTeamID(
    $teamID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamGroupByDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamGroupByDataByTeamID(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        teamID
        tenure
        level
        manager
        leaderofleader
        teamAdmins
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTeamGroupByDataByOwner = /* GraphQL */ `
  query ListTeamGroupByDataByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamGroupByDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamGroupByDataByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        teamID
        tenure
        level
        manager
        leaderofleader
        teamAdmins
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCalendarsByOwner = /* GraphQL */ `
  query ListCalendarsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        description
        owner
        teamID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listShareCalendarsByRecipient = /* GraphQL */ `
  query ListShareCalendarsByRecipient(
    $recipient: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShareCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareCalendarsByRecipient(
      recipient: $recipient
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        calendarID
        calendar {
          id
          group
          name
          description
          owner
          teamID
          createdAt
          updatedAt
          __typename
        }
        recipient
        sender
        description
        owner
        teamID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listShareCalendarsByOwner = /* GraphQL */ `
  query ListShareCalendarsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShareCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareCalendarsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        calendarID
        calendar {
          id
          group
          name
          description
          owner
          teamID
          createdAt
          updatedAt
          __typename
        }
        recipient
        sender
        description
        owner
        teamID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listShareCalendarsByTeamID = /* GraphQL */ `
  query ListShareCalendarsByTeamID(
    $teamID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShareCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareCalendarsByTeamID(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        calendarID
        calendar {
          id
          group
          name
          description
          owner
          teamID
          createdAt
          updatedAt
          __typename
        }
        recipient
        sender
        description
        owner
        teamID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
