import React, { useState, useEffect, useMemo } from 'react';
import { API, Analytics } from 'aws-amplify';
import {
  EuiPanel,
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiStat,
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui';
import { css } from '@emotion/react';

export function LeaderBoard() {
  let sortedList = [];
  sortedList.push({
    alias: "andklee",
    totalHours: 500,
    totalTasks: 750
  });
  sortedList.push({
    alias: "erinq",
    totalHours: 400,
    totalTasks: 550
  });
  
  return (
    <>
      <EuiFlexGroup justifyContent="spaceAround">
        <EuiFlexItem style={{ maxWidth: 900 }}>
        <EuiSpacer size="xs"/>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiPanel>
                <EuiTitle><h1>Weekly</h1></EuiTitle>
                <EuiSpacer size="xs"/>
                <EuiText>Top 10 users from the week of blank to blank.</EuiText>
                {sortedList.map((obj, i) => {
                  return (
                    <>
                      <EuiPanel>
                        <EuiFlexGroup>
                          <EuiFlexItem grow={1}><EuiStat title={i+1} description={obj.alias} titleSize ='m' /></EuiFlexItem>
                          <EuiFlexItem grow={1}><EuiAvatar size="xl" name="Andrew Lee" imageUrl={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+obj.alias} type="user"/></EuiFlexItem>
                          <EuiFlexItem grow={1}><EuiStat title={obj.totalHours} description="Hours" titleSize ='m' /></EuiFlexItem>
                          <EuiFlexItem grow={1}><EuiStat title={obj.totalTasks} description="Tasks" titleSize ='m' /></EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                      <EuiSpacer size="xs"/>
                    </>
                  );
                })}
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel>
                <EuiTitle><h1>All Time</h1></EuiTitle>
                <EuiSpacer size="xs"/>
                <EuiText>Top 10 users from all time.</EuiText>
                {sortedList.map((obj, i) => {
                  return (
                    <>
                      <EuiPanel>
                        <EuiFlexGroup>
                          <EuiFlexItem grow={1}><EuiStat title={i+1} description={obj.alias} titleSize ='m' /></EuiFlexItem>
                          <EuiFlexItem grow={1}><EuiAvatar size="xl" name="Andrew Lee" imageUrl={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+obj.alias} type="user"/></EuiFlexItem>
                          <EuiFlexItem grow={1}><EuiStat title={obj.totalHours} description="Hours" titleSize ='m' /></EuiFlexItem>
                          <EuiFlexItem grow={1}><EuiStat title={obj.totalTasks} description="Tasks" titleSize ='m' /></EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                      <EuiSpacer size="xs"/>
                    </>
                  );
                })}
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}