import React from 'react';
import { API } from 'aws-amplify';
import { 
    updateUserFn as updateUserFnMutation
} from './graphql/mutations'; 
import {
    EuiFormRow,
    EuiIcon,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
    EuiSelect,
    EuiSwitch,
    EuiPageTemplate,
    useEuiTheme,
    logicals
  } from '@elastic/eui';
import { css } from '@emotion/react';
import { timeFormats } from './Constants';

export function SettingsPage({userData,userSettings,setUserSettings,notificationPermission,setNotificationPermission,displayStartTime,setActiveMenu,setWorkWeek,setCalendarSlots,setCalendarSteps,setCalendarHeight}) {
    const { euiTheme } = useEuiTheme();
    return(
      <>
        <EuiPageTemplate restrictWidth="100%" template="empty">
            <div>
          <EuiFlexGroup>
            <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    ${logicals['width']}: 300px;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h3>Calendar</h3>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="calendar" size='l'/>Outlook Integration</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='Task Email Invites' 
                      checked={userSettings.calendarInvite}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'calendarInvite': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: {'calendarInvite':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="clock" size='l'/>Time Format</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Time Format'
                      options={timeFormats}
                      value={Number(userSettings.timeformat)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'timeformat': e.target.value});
                        API.graphql({ query: updateUserFnMutation, variables: { input: {'timeformat':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="calendar" size='l'/>Display Start Time</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Display Start Time'
                      options={displayStartTime}
                      value={Number(userSettings.calstarttime)}
                      onChange={(e) => {
                        if(Number(userSettings.calendtime) > Number(e.target.value)) {
                          setUserSettings({ ...userSettings, 'calstarttime': e.target.value});
                          API.graphql({ query: updateUserFnMutation, variables: { input: { 'calstarttime':e.target.value}} }).catch((error)=>{});
                        }
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="calendar" size='l'/>Display End Time</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Display End Time'
                      options={displayStartTime}
                      value={Number(userSettings.calendtime)}
                      onChange={(e) => {
                        if(Number(userSettings.calstarttime) < Number(e.target.value)) {
                          setUserSettings({ ...userSettings, 'calendtime': e.target.value});
                          API.graphql({ query: updateUserFnMutation, variables: { input: { 'calendtime':e.target.value}} }).catch((error)=>{});
                        }
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="wrench" size='l'/>Work Week</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Work Week'
                      options={[
                        { value: 1, text: '1' },
                        { value: 2, text: '2' },
                        { value: 3, text: '3' },
                        { value: 4, text: '4' },
                        { value: 5, text: '5' },
                        { value: 6, text: '6' },
                        { value: 7, text: '7' },
                      ]}
                      value={Number(userSettings.workWeek)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'workWeek': e.target.value});
                        setWorkWeek(e.target.value);
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'workWeek':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="continuityWithin" size='l'/>Calendar Steps</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Calendar Steps'
                      options={[
                        { value: 5, text: '5 min' },
                        { value: 10, text: '10 min' },
                        { value: 15, text: '15 min' },
                        { value: 30, text: '30 min' },
                        { value: 60, text: '60 min' },
                      ]}
                      value={Number(userSettings.calendarSteps)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'calendarSteps': e.target.value});
                        setCalendarSteps(e.target.value);
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'calendarSteps':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="continuityWithin" size='l'/>Calendar Slots</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Calendar Slots'
                      options={[
                        { value: 1, text: '1' },
                        { value: 2, text: '2' },
                        { value: 3, text: '3' },
                        { value: 4, text: '4' },
                      ]}
                      value={Number(userSettings.calendarSlots)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'calendarSlots': e.target.value});
                        setCalendarSlots(e.target.value);
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'calendarSlots':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="sortable" size='l'/>Calendar Height</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Calendar Height'
                      options={[
                        { value: 600, text: 'Extra Small' },
                        { value: 800, text: 'Small' },
                        { value: 1000, text: 'Normal' },
                        { value: 1500, text: 'Large' },
                        { value: 2000, text: 'Extra Large' },
                      ]}
                      value={Number(userSettings.calendarHeight)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'calendarHeight': e.target.value});
                        setCalendarHeight(Number(e.target.value));
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'calendarHeight':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    padding-left: calc(${euiTheme.size.s});
                    ${logicals['width']}: 300px;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h3>Notifications</h3>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="alert" size='l'/>Enabled Notification Types</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow
                    isInvalid={!notificationPermission}
                    error={"Disabled in Browser."}
                  >
                    <EuiSwitch 
                      label='Web' 
                      checked={userSettings.webnotify} 
                      disabled={!notificationPermission} 
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'webnotify': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'webnotify':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='Email' 
                      checked={userSettings.emailnotify}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'emailnotify': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'emailnotify':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiFormRow
                    isInvalid={!userSettings.phone}
                    error={"No phone number set."}
                  >
                    <EuiSwitch 
                      label='SMS' 
                      checked={userSettings.phonenotify}
                      disabled={!userSettings.phone} 
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'phonenotify': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'phonenotify':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="clock" size='l'/>Notification Timing</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='10 Minute Notification' 
                      checked={userSettings.ftmin}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'ftmin': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'ftmin':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='5 Minute Notification' 
                      checked={userSettings.fvmin}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'fvmin': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'fvmin':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    ${logicals['width']}: 300px;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h3>Other Settings</h3>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="training" size='l'/>Tutorial</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='Tutorial Enabled' 
                      checked={userSettings.firstVisit}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'firstVisit': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'firstVisit':e.target.checked}} }).catch((error)=>{});;
                        setActiveMenu("home");
                      }}/>
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="eye" size='l'/>Goal/Project Visibility</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSwitch 
                      label="Show hidden goals/projects"
                      checked={userSettings.hideprojects}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'hideprojects': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'hideprojects':e.target.checked}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiSwitch 
                      label="Show default projects"
                      checked={!userSettings.hidedefaultprojects}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'hidedefaultprojects': !e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'hidedefaultprojects':!e.target.checked}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                </EuiPanel>
              </EuiFlexItem>
          </EuiFlexGroup>
          </div>
        </EuiPageTemplate>
      </>
    )
  }