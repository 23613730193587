import React, { useState, useEffect } from 'react';
import { API, Analytics } from 'aws-amplify';
import { 
    createCalendarFn as createCalendarFnMutation,
    updateCalendarFn as updateCalendarFnMutation,
    deleteCalendarFn as deleteCalendarFnMutation,
    updateUserFn as updateUserFnMutation,
    deleteShareCalendar as deleteShareCalendarMutation,
    createShareCalendarFn as createShareCalendarFnMutation
  } from './graphql/mutations';  
import {
    EuiPopover,
    EuiFormRow,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSelect,
    EuiButtonIcon,
    EuiPanel,
    EuiText,
    EuiAvatar,
    EuiSpacer,
    EuiForm,
    EuiFieldText,
    EuiButton,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiCallOut,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiKeyPadMenuItem,
    EuiIcon,
    EuiTitle,
    EuiSelectable
  } from '@elastic/eui';

export function CalendarShare({userData, calendars, sharedToMeCalendars, setSharedToMeCalendars, sharedByMeCalendars, setSharedByMeCalendars, setIsToolsMenuOpen, keyPad}) {
  const [openCalendarShare, setOpenCalendarShare] = useState(false);
  const [calendarToShare, setCalendarToShare] = useState({});
  const [shareCalendarFormData,setShareCalendarFormData] = useState({});
  const [error,setError] = useState("");
  const filteredCalendars = calendars.filter((obj)=>!obj.teamid&&obj.createtask);

  async function checkSharedByMeCalendars(calendarID, recipient) {
    let foundDuplicate = false;
    for(let i in sharedByMeCalendars) {
      if(sharedByMeCalendars[i].recipient === recipient && sharedByMeCalendars[i].calendarid === calendarID) {
        foundDuplicate = true;
      }
    }
    return foundDuplicate;
  }

  async function removeSharedByMeCalendars() {
    let shareCalendarIDsToRemove = {};
    for(let i in sharedByMeCalendars) {
      if(sharedByMeCalendars[i].checked === "on") {
        try {
          await API.graphql({ query: deleteShareCalendarMutation, variables: {'input': {'id': sharedByMeCalendars[i].sharecalendarid} }});
          shareCalendarIDsToRemove[sharedByMeCalendars[i].sharecalendarid] = 1;
        } catch(e) {
          setError ("Error deleting calendar share.");
        }
      }
    }
    let newSharedByMeCalendars = sharedByMeCalendars.filter((obj)=> !shareCalendarIDsToRemove[obj.sharecalendarid]);
    setSharedByMeCalendars(newSharedByMeCalendars);
  }

  async function removeSharedToMeCalendars() {
    let shareCalendarIDsToRemove = {};
    for(let i in sharedToMeCalendars) {
      if(sharedToMeCalendars[i].checked === "on") {
        try {
          await API.graphql({ query: deleteShareCalendarMutation, variables: {'input': {'id': sharedToMeCalendars[i].sharecalendarid} }});
          shareCalendarIDsToRemove[sharedToMeCalendars[i].sharecalendarid] = 1;
        } catch(e) {
          setError("Error deleting calendar share.")
        }
      }
    }
    let newSharedToMeCalendars = sharedToMeCalendars.filter((obj)=> !shareCalendarIDsToRemove[obj.sharecalendarid]);
    setSharedToMeCalendars(newSharedToMeCalendars);
  }

  async function shareCalendarFn() {
    if(!calendarToShare.value || !shareCalendarFormData.recipient) return;
    if(await checkSharedByMeCalendars(calendarToShare.value,"amazonfederate_"+shareCalendarFormData.recipient)) return;
    let params = {
      calendarID: calendarToShare.value,
      recipient: "amazonfederate_"+shareCalendarFormData.recipient,
      sender: userData.username,
      description: shareCalendarFormData.description
    };
    try {
      const apiData =  await API.graphql({ query: createShareCalendarFnMutation, variables: { input: params } });
      apiData.data.createShareCalendar = apiData.data.createShareCalendarFn;
      let newSharedByMeCalendar = {
        "key": apiData.data.createShareCalendar.calendar.id,
        "value": apiData.data.createShareCalendar.calendar.id,
        "sharecalendarid": apiData.data.createShareCalendar.id,
        "recipient": apiData.data.createShareCalendar.recipient,
        "calendarid": apiData.data.createShareCalendar.calendarID,
        "text" : apiData.data.createShareCalendar.calendar.name + " shared to "+apiData.data.createShareCalendar.recipient.replace("amazonfederate_",""),
        "label" : apiData.data.createShareCalendar.calendar.name + " shared to "+apiData.data.createShareCalendar.recipient.replace("amazonfederate_",""),
        "description": apiData.data.createShareCalendar.description,
        data: {
          secondaryContent: apiData.data.createShareCalendar.description,
        }
      };
      setSharedByMeCalendars([newSharedByMeCalendar,...sharedByMeCalendars])
    } catch(e) {
      console.log(e);
      setError("The recipient is invalid. Check that you are the not the recipient, the recipient is a TimeBlok user or you have not shared the same calendar with that user.");
    }
  }

  return (
    <>
      {keyPad ? 
      <EuiKeyPadMenuItem
        label="Calendar Share"
        onClick ={()=>{
          setOpenCalendarShare(true);
        }}
      >
        <EuiIcon type="calendar" size="l" color="primary"/>
      </EuiKeyPadMenuItem> 
      :
      <EuiButtonEmpty onClick ={()=>{
        setOpenCalendarShare(true);
      }}><EuiAvatar name="Calendar" type="space" iconType="calendar" size="s" color="#0071c2" />&nbsp;Calendar Share</EuiButtonEmpty>
      }
      {openCalendarShare && <EuiModal
        onClose={()=>{setOpenCalendarShare(false);}}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1><EuiAvatar name="Calendar" type="space" iconType="calendar" size="m" color="#0071c2" />&nbsp;Calendar Share</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
            <p>
              {error}
            </p>
          </EuiCallOut><EuiSpacer size='s' /></> }
          <EuiCallOut title="Note" color="warning" iconType="help">
            <p>
              Note that you are able to share your calendar with another user. That user will be able to view but not edit/create tasks in your calendar.<br/>
              <ul>
                <li>All tasks created in the calendar.</li>
                <li>Any projects that are associated with those tasks.</li>
                <li>Any goals that are associated with those projects.</li>
              </ul>
            </p>
          </EuiCallOut>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem>
              <CalendarSelect userData={userData} activeCalendar={calendarToShare} setActiveCalendar={setCalendarToShare} calendars={filteredCalendars} disableButtons={true} noInitial={true} title="Calendar to share" display="columnCompressed"/>
              <EuiSpacer/>
              <EuiFormRow label="Share with User Alias" helpText="This is the user alias that you will share your Calendar with." display="columnCompressed">
                <EuiFieldText
                  onChange={e => setShareCalendarFormData({...shareCalendarFormData, "recipient": e.target.value})}
                  placeholder="User Alias"
                  value={shareCalendarFormData.recipient}
                />
              </EuiFormRow>
              <EuiFormRow label="Description" display="columnCompressed">
                <EuiFieldText
                  onChange={e => setShareCalendarFormData({...shareCalendarFormData, "description": e.target.value})}
                  placeholder="Description"
                  value={shareCalendarFormData.description}
                />
              </EuiFormRow>
              <EuiFormRow>
                <EuiButton 
                  color="primary" 
                  onClick={()=>{
                    shareCalendarFn();
                  }} 
                  fill
                >
                  Share
                </EuiButton>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          {sharedByMeCalendars.length !== 0 && <EuiFlexGroup>
            <EuiFlexItem>
              <EuiTitle size="xs">
                <h4>Calendars shared to others</h4>
              </EuiTitle>
              <EuiSelectable
                aria-label="Calendars shared to others"
                searchable
                options={sharedByMeCalendars}
                onChange={(options) => {
                  setSharedByMeCalendars(options);
                }}
              >
                {(list, search) => (
                  <>
                    <EuiFlexGroup style={{width: 100}}>
                      <EuiFlexItem>
                        <EuiButton
                          color="danger" 
                          size="s"
                          onClick={()=>{
                            removeSharedByMeCalendars();
                          }} 
                          fill
                        >
                          Remove Selected
                        </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    {search}
                    {list}
                  </>
                )}
              </EuiSelectable>
            </EuiFlexItem>
          </EuiFlexGroup>}
          {sharedToMeCalendars.length !== 0 && <EuiFlexGroup>
            <EuiFlexItem>
              <EuiTitle size="xs">
                <h4>Calendars shared to me</h4>
              </EuiTitle>
              <EuiSelectable
                aria-label="Calendars shared to me"
                searchable
                options={sharedToMeCalendars}
                onChange={(options) => {
                  setSharedToMeCalendars(options);
                }}
              >
                {(list, search) => (
                  <>
                    <EuiFlexGroup style={{width: 100}}>
                      <EuiFlexItem>
                        <EuiButton
                          color="danger" 
                          size="s"
                          onClick={()=>{
                            removeSharedToMeCalendars();
                          }} 
                          fill
                        >
                          Remove Selected
                        </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    {search}
                    {list}
                  </>
                )}
              </EuiSelectable>
            </EuiFlexItem>
          </EuiFlexGroup>}
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton 
            color="primary" 
            onClick={()=>{
              setOpenCalendarShare(false);
              if(setIsToolsMenuOpen) {
                setIsToolsMenuOpen(false);
              }
            }} 
            fill
          >
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>}
    </>
  );
}  

export function CreateCalendar({userData,calendars,setCalendars,setActiveCalendar,reportCalendars,setReportCalendars,doneCallback,doneCallbackParam}) {
  const initialCalendarFormData = {name:'', description: ''};
  const [calendarFormData,setCalendarFormData] = useState(initialCalendarFormData);
  const [error,setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function createCalendar() {
    if (!calendarFormData.name) return;
    calendarFormData.group = "1";
    setIsLoading(true);
    try {
      const apiData =  await API.graphql({ query: createCalendarFnMutation, variables: { input: calendarFormData } });
      apiData.data.createCalendar = apiData.data.createCalendarFn;
      let newCalendarObj = {"key":apiData.data.createCalendar.id, "value": apiData.data.createCalendar.id, "text": apiData.data.createCalendar.name, "label": apiData.data.createCalendar.name, "description": apiData.data.createCalendar.description, "createtask": true, "owner": apiData.data.createCalendar.owner};
      setCalendars([...calendars, newCalendarObj]);
      setReportCalendars([...reportCalendars, newCalendarObj]);
      setCalendarFormData(initialCalendarFormData);
      if(setActiveCalendar) {
        setActiveCalendar(newCalendarObj);
      }
      Analytics.record({ 
        name: 'createCalendar'
      });
      if(doneCallback) {
        doneCallback(doneCallbackParam);
      }
    } catch(e) {
      setError("Calendar limit reached.");
    }
    setIsLoading(false);
  }
  
  return (
    <div>
      <EuiPanel paddingSize="m" grow={false}>
        <EuiText grow={false} textAlign='center'>
          <h3><EuiAvatar name="Create" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Create Calendar</h3>
        </EuiText>
        <EuiSpacer size='s' />
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiForm>
          <EuiFormRow label="Name" display="columnCompressed">
            <EuiFieldText
              onChange={e => setCalendarFormData({ ...calendarFormData, 'name': e.target.value})}
              placeholder="Calendar name"
              value={calendarFormData.name}
            />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiFormRow label="Description" display="columnCompressed">
            <EuiFieldText
              onChange={e => setCalendarFormData({ ...calendarFormData, 'description': e.target.value})}
              placeholder="Description"
              value={calendarFormData.description}
            />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiButton fill onClick={createCalendar} isLoading={isLoading}>
            Create Calendar
          </EuiButton>
        </EuiForm>
      </EuiPanel>
    </div>
  );
}
  
export function EditCalendarModal({editCalendar, setEditCalendar, calendars, setCalendars, setActiveCalendar}) {
  const [error,setError] = useState("");
  const [editCalendarFormData,setEditCalendarFormData] = useState(editCalendar);

  useEffect(() => {
  }, []);

  async function editCalendarFn() {
    if (!editCalendarFormData.value) return;
    try {
      let apiData = await API.graphql({ query: updateCalendarFnMutation, variables: { input: {id: editCalendarFormData.key, 'name': editCalendarFormData.text, 'description': editCalendarFormData.description}} });
      apiData.data.updateCalendar = apiData.data.updateCalendarFn;
      let newCalendars = calendars.filter(obj => obj.key !== editCalendarFormData.key);
      setCalendars([...newCalendars, {"key":apiData.data.updateCalendar.id, "value": apiData.data.updateCalendar.id, "text": apiData.data.updateCalendar.name, "label": apiData.data.updateCalendar.name, "description": apiData.data.updateCalendar.description, "createtask": true, "owner": apiData.data.updateCalendar.owner}]);
      setActiveCalendar({"key":apiData.data.updateCalendar.id, "value": apiData.data.updateCalendar.id, "text": apiData.data.updateCalendar.name, "label": apiData.data.updateCalendar.name, "description": apiData.data.updateCalendar.description, "createtask": true, "owner": apiData.data.updateCalendar.owner});
      setEditCalendarFormData({});
    } catch(e) {
      setError("Could not edit Calendar.");
    }
  }
  
  return (
    <EuiModal
      style={{ width: 400 }}
      onClose={() => setEditCalendar(null)}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1><EuiAvatar name="Edit" type="space" iconType="plus" size="m" color="#69707D" />&nbsp;Edit Calendar</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
            <p>
              {error}
            </p>
          </EuiCallOut><EuiSpacer size='s' /></> }
        {editCalendar.text==="Primary"  && <EuiCallOut title="Note" color="warning" iconType="help">
        <p>
          You cannot edit the Primary calendar.
        </p>
        </EuiCallOut>}
          
        <EuiForm>
          <EuiFormRow label="Name">
            <EuiFieldText
              isDisabled={(editCalendar.text==="Primary")}
              onChange={e => setEditCalendarFormData({ ...editCalendarFormData, 'text': e.target.value})}
              placeholder="Calendar name"
              value={editCalendarFormData.text}
            />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiFormRow label="Description">
            <EuiFieldText
              isDisabled={(editCalendar.text==="Primary")}
              onChange={e => setEditCalendarFormData({ ...editCalendarFormData, 'description': e.target.value})}
              placeholder="Description"
              value={editCalendarFormData.description}
            />
          </EuiFormRow>
        </EuiForm>
        <EuiSpacer/>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => {
          setEditCalendar(null);
        }}>Cancel</EuiButtonEmpty>

        <EuiButton 
          color="text"
          fill
          isDisabled={(editCalendar.text==="Primary")}
          onClick={() => {
            editCalendarFn();
            setEditCalendar(null);
          }}
        >
          Save
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}
  
export function DeleteCalendarModal({userData, deleteCalendar, setDeleteCalendar, calendars, setCalendars, setActiveCalendar}) {
  const [newCalendar, setNewCalendar] = useState({});
  const [isLoading,setIsLoading] = useState(false);
  const [error,setError] = useState("");
  const filteredCalendars = calendars.filter((obj) => obj.key !== deleteCalendar.key && obj.createtask && !obj.teamid);

  async function deleteCalendarFn() {
    let updatedCalendars = calendars.filter(obj => obj.key !== deleteCalendar.key);
    setCalendars(updatedCalendars);
    try {
      if(!newCalendar.key) {
        newCalendar.key = "";
      }
      await API.graphql({ query: deleteCalendarFnMutation, variables: {'calendarID': deleteCalendar.key, 'newCalendarID': newCalendar.key} });
      if(newCalendar.key) {
        setActiveCalendar(newCalendar);
      } else {
        setActiveCalendar(calendars[0]);
      }
      setDeleteCalendar(null);
    } catch(e) {
      setError("Error deleting calendar.");
    }

    setNewCalendar({});
    setIsLoading(false);
  }

  return (
    <EuiModal
      style={{ width: 400 }}
      onClose={() => setDeleteCalendar(null)}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1><EuiAvatar name="Delete" type="space" iconType="plus" size="m" color="#BD271E" />&nbsp;Delete Calendar</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiCallOut title="Note" color="warning" iconType="help">
          {deleteCalendar.text==="Primary" ? 
          <p>
            You cannot delete the Primary calendar.
          </p>
          :
          <p>
            Deleting a calendar will also delete all tasks associated with the calendar. You can also transfer all the tasks to another calendar before it is deleted.
          </p>
          }
        </EuiCallOut>
        <EuiSpacer/>
        <CalendarSelect activeCalendar={newCalendar} setActiveCalendar={setNewCalendar} calendars={filteredCalendars} setCalendars={setCalendars} disableButtons={true} noInitial={true} title="Transfer tasks to calendar" display="columnCompressed"/>
        <EuiSpacer/>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => {
          setDeleteCalendar(null);
        }}>Cancel</EuiButtonEmpty>

        <EuiButton 
          color="danger"
          fill
          isLoading={isLoading}
          isDisabled={(deleteCalendar.text==="Primary")}
          onClick={() => {
            setIsLoading(true);
            deleteCalendarFn();
          }}
        >
          Delete Calendar
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}  

export function CalendarSelect({userData,activeCalendar,setActiveCalendar,calendars,setCalendars,setNewCalendar,disableButtons,noInitial,reportCalendars,setReportCalendars,title,display}) {
  const [openCreatePopup,setOpenCreatePopup] = useState(false);
  const [editCalendar,setEditCalendar] = useState(null);
  const [deleteCalendar,setDeleteCalendar] = useState(null);
  
  return (
    <>
      {activeCalendar && 
      <>
        <EuiFlexGroup>
          <EuiFlexItem style={{minWidth: 170}}>
            <EuiFormRow label={title} display={display}>
              <EuiSelect
                options={calendars}
                value={activeCalendar.value}
                hasNoInitialSelection={noInitial}
                compressed={true}
                onChange={(event) => {
                  let calendarObj = calendars.find((obj) => obj.value === event.target.value);
                  setActiveCalendar(calendarObj);
                  if(userData) {
                    let inputObj = {'selectedCalendarID':calendarObj.key};
                    if(calendarObj.teamid) {
                      inputObj.teamID = calendarObj.teamid;
                    }
                    API.graphql({ query: updateUserFnMutation, variables: { input: inputObj} }).catch((error)=>{});
                  }
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {!disableButtons &&
          <EuiFlexItem>
            <div>
              <EuiPopover
                button={<EuiButtonIcon aria-label="Add Calendar" iconType="listAdd" display="fill" size="s" color="primary"
                  onClick={()=>{
                    if(!openCreatePopup) {
                      setOpenCreatePopup(true);
                    } else {
                      setOpenCreatePopup(false);
                    }
                  }}
                />}
                isOpen={openCreatePopup}
                closePopover={()=>{setOpenCreatePopup(false)}}
              >
                <CreateCalendar userData={userData} calendars={calendars} setCalendars={setCalendars} setActiveCalendar={setActiveCalendar} reportCalendars={reportCalendars} setReportCalendars={setReportCalendars} doneCallback={setOpenCreatePopup} doneCallbackParam={false}/>
              </EuiPopover>
              &nbsp;
              <span>
                <EuiButtonIcon 
                  color="text"
                  aria-label="Edit"
                  iconType="documentEdit"
                  size="s"
                  display="fill"
                  isDisabled={activeCalendar.owner !== userData.username || activeCalendar.teamid}
                  onClick={()=>{
                    setEditCalendar(activeCalendar);
                  }}
                />
              </span>
              &nbsp;
              <span>
                <EuiButtonIcon 
                  color="danger"
                  aria-label="Delete"
                  iconType="trash"
                  size="s"
                  display="fill"
                  isDisabled={activeCalendar.owner !== userData.username || activeCalendar.teamid }
                  onClick={()=>{
                    setDeleteCalendar(activeCalendar);
                  }}
                />
              </span>
            </div>
          </EuiFlexItem>}
        </EuiFlexGroup>
        {editCalendar && <EditCalendarModal editCalendar={editCalendar} setEditCalendar={setEditCalendar} setActiveCalendar={setActiveCalendar} calendars={calendars} setCalendars={setCalendars}/>}
        {deleteCalendar && <DeleteCalendarModal userData={userData} deleteCalendar={deleteCalendar} setDeleteCalendar={setDeleteCalendar} setActiveCalendar={setActiveCalendar} calendars={calendars} setCalendars={setCalendars}/>}
      </>
      }
    </>
  );
}