import React, { useState, useEffect, useMemo } from 'react';
import { API, Analytics } from 'aws-amplify';
import { 
  getProject
} from './graphql/queries';  
import { 
  createProjectFn as createProjectFnMutation,
  updateProjectFn as updateProjectFnMutation,
  deleteProjectJob as deleteProjectJobMutation,
  updateGoalFn as updateGoalFnMutation,
  updateUserFn as updateUserFnMutation
} from './graphql/mutations';  
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
  EuiPopover,
  EuiButtonIcon,
  EuiPanel,
  EuiText,
  EuiAvatar,
  EuiSpacer,
  EuiFormRow,
  EuiFieldText,
  EuiForm,
  EuiSwitch,
  EuiColorPicker,
  EuiButton,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiCallOut,
  useColorPickerState,
  EuiBadge,
  euiDragDropReorder,
  EuiPageTemplate,
  EuiTourStep,
  EuiDragDropContext,
  EuiDroppable,
  EuiDraggable,
  EuiStat,
  EuiHealth,
  EuiContextMenu,
  EuiIcon,
  useEuiTheme,
  logicals
} from '@elastic/eui';
import { css } from '@emotion/react';
import { initialProjectFormState, defaultProjects } from './Constants';
import { 
  CreateGoal,
  EditGoalModal,
  DeleteGoalModal
} from './Goal';

export function TagSelect({editProject, editProjectFormData, projects, setProjects}) {
  const [tags,setTags] = useState([]);
  const [openPopup,setOpenPopup] = useState(false);
  const [openPopupDelete,setOpenPopupDelete] = useState(false);

  useEffect(() => {
    if(editProjectFormData.tags) {
      setTags(editProjectFormData.tags.map((elem)=>({"text": elem, "label": elem, "key": elem, "value":elem})));
    }
  },[editProjectFormData]);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem style={{minWidth: 200}}>
          <div>
            {tags.map((tag) => (
              <EuiBadge color="primary">{tag.text}</EuiBadge>
            ))}
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <span>
          <EuiPopover
            button={<EuiButtonIcon aria-label="Create Tag" iconType="listAdd" display="fill" size="m"
              onClick={()=>{
                if(!openPopup) {
                  setOpenPopup(true);
                } else {
                  setOpenPopup(false);
                }
              }}
            />}
            isOpen={openPopup}
            closePopover={()=>{setOpenPopup(false)}}
          >
            <CreateTag editProjectFormData={editProjectFormData} projects={projects} setProjects={setProjects} tags={tags} setTags={setTags} setOpenPopup={setOpenPopup}/>
          </EuiPopover>
          &nbsp;
          <EuiPopover
            button={<EuiButtonIcon aria-label="Delete Tag" iconType="trash" display="fill" size="m" color="danger"
              onClick={()=>{
                if(!openPopupDelete) {
                  setOpenPopupDelete(true);
                } else {
                  setOpenPopupDelete(false);
                }
              }}
            />}
            isOpen={openPopupDelete}
            closePopover={()=>{setOpenPopupDelete(false)}}
          >
            <DeleteTag editProjectFormData={editProjectFormData} projects={projects} setProjects={setProjects} tags={tags} setTags={setTags} setOpenPopupDelete={setOpenPopupDelete}/>
          </EuiPopover>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export function DeleteTag({editProjectFormData,projects,setProjects,tags,setTags,setOpenPopupDelete}) {
  const [selectionValue,setSelectionValue] = useState(null);
  const [error,setError] = useState("");

  async function deleteTag() {
    let tagName = tags.filter(obj=> obj !== selectionValue);
    let newTags = editProjectFormData.tags.filter(obj => obj !== tagName[0].text);
    editProjectFormData.tags = newTags;
    try {
      let apiData = await API.graphql({ query: updateProjectFnMutation, variables: { input: {id: editProjectFormData.id, 'tags': newTags}} });
      apiData.data.updateProject = apiData.data.updateProjectFn;
      setTags(newTags.map((elem)=>({"text": elem, "label": elem, "key": elem, "value":elem})));
      let newProjects = projects.filter(obj => obj.key !== editProjectFormData.id);
      setProjects([...newProjects, {"key":apiData.data.updateProject.id, "value": apiData.data.updateProject.id, "text": apiData.data.updateProject.name, "label": apiData.data.updateProject.name, "notes": apiData.data.updateProject.notes, "color": apiData.data.updateProject.color, "goalid": apiData.data.updateProject.goalID, "tags": apiData.data.updateProject.tags, "hidden": apiData.data.updateProject.hidden}]);
      setOpenPopupDelete(false);
    } catch(e) {
      setError("Error deleting tag.");
      setTimeout(()=>{setOpenPopupDelete(false);},5000);
    }
  }

  return(
    <>
      <EuiPanel paddingSize="m" grow={false}>
        <EuiText grow={false} textAlign='center'>
          <h3><EuiAvatar name="Delete" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Delete Tag</h3>
        </EuiText>
        <EuiSpacer size='s' />
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiForm>
          <EuiFormRow label="Tag">
            <EuiSelect
              placeholder='Associate Project'
              options={tags}
              value={selectionValue}
              hasNoInitialSelection={true}
              onChange={(e) => {
                setSelectionValue(e.target.value);
              }}
            />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiButton fill onClick={deleteTag}>
            Delete Tag
          </EuiButton>
        </EuiForm>
      </EuiPanel>
    </>
  );
}

export function CreateTag({editProjectFormData,projects,setProjects,tags,setTags,setOpenPopup}) {
  const [tagCreate,setTagCreate] = useState("");
  const [error,setError] = useState("");

  async function createTag() {
    if(!editProjectFormData.tags) {
      editProjectFormData.tags = [];
    }
    editProjectFormData.tags.push(tagCreate);
    try {
      let apiData = await API.graphql({ query: updateProjectFnMutation, variables: { input: {id: editProjectFormData.id, 'tags': editProjectFormData.tags}} });
      apiData.data.updateProject = apiData.data.updateProjectFn;
      setTags(editProjectFormData.tags.map((elem)=>({"text": elem, "label": elem, "key": elem, "value":elem})));
      let newProjects = projects.filter(obj => obj.key !== editProjectFormData.id);
      setProjects([...newProjects, {"key":apiData.data.updateProject.id, "value": apiData.data.updateProject.id, "text": apiData.data.updateProject.name, "label": apiData.data.updateProject.name, "notes": apiData.data.updateProject.notes, "color": apiData.data.updateProject.color, "goalid": apiData.data.updateProject.goalID, "tags": apiData.data.updateProject.tags, "hidden": apiData.data.updateProject.hidden}]);
      setOpenPopup(false);
    } catch(e) {
      setError("Error creating tag.");
      setTimeout(()=>{setOpenPopup(false);},5000);
    }
  }

  return(
    <>
      <EuiPanel paddingSize="m" grow={false}>
        <EuiText grow={false} textAlign='center'>
          <h3><EuiAvatar name="Create" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Create Tag</h3>
        </EuiText>
        <EuiSpacer size='s' />
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiForm>
          <EuiFormRow label="Name" display="columnCompressed">
            <EuiFieldText
              onChange={e => setTagCreate(e.target.value)}
              placeholder="Tag name"
              value={tagCreate}
            />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiButton fill onClick={createTag}>
            Create Tag
          </EuiButton>
        </EuiForm>
      </EuiPanel>
    </>
  );
}

export function CreateProject({userData,projects,setProjects,doneCallback,doneCallbackParam,setSelectionValue,taskFormData,teamID,teamCalendarID,activeCalendar}) {
  const [projectFormData,setProjectFormData] = useState(initialProjectFormState);
  const [color, setColor, errors] = useColorPickerState();
  const [error,setError] = useState("");
  const isInvalid = useMemo(() => color !== '' && !!errors, [color, errors]);

  useEffect(() => {
    if(color) {
      setProjectFormData(projectFormData => ({ ...projectFormData, 'color': color}));
    }
  },[color]);

  async function createProject() {
    setError("");
    if (!projectFormData.name) return;
    projectFormData.userID = userData.username;
    projectFormData.calendarID = activeCalendar.value;
    if(teamID) {
      projectFormData.teamID = teamID;
      projectFormData.calendarID = [teamCalendarID];
    }
    projectFormData.name = projectFormData.name.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,50);
    projectFormData.notes = projectFormData.notes.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,180);
    try {
      const apiData =  await API.graphql({ query: createProjectFnMutation, variables: { input: projectFormData } });
      apiData.data.createProject = apiData.data.createProjectFn;
      setProjects([...projects, {"key":apiData.data.createProject.id, "value": apiData.data.createProject.id, "text": apiData.data.createProject.name, "label": apiData.data.createProject.name, "notes": apiData.data.createProject.notes, "color": apiData.data.createProject.color, "calendarid": apiData.data.createProject.calendarID, "hidden": apiData.data.createProject.hidden}]);
      setProjectFormData(initialProjectFormState);
      if(setSelectionValue) {
        taskFormData.projectID = apiData.data.createProject.id;
        taskFormData.projectName = apiData.data.createProject.name;
        setSelectionValue(apiData.data.createProject.id);
      }
      Analytics.record({ 
        name: 'createProject'
      });
    } catch(e) {
      setError("Error creating project.");
    }
    if(doneCallback) {
      doneCallback(doneCallbackParam);
    }
  }
  
  return (
    <div>
      <EuiPanel paddingSize="m" grow={false}>
        <EuiText grow={false} textAlign='center'>
          <h3><EuiAvatar name="Create" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Create Project</h3>
        </EuiText>
        <EuiSpacer size='s' />
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiForm>
          <EuiFormRow label="Name" display="columnCompressed">
            <EuiFieldText
              onChange={e => setProjectFormData({ ...projectFormData, 'name': e.target.value})}
              placeholder="Project name"
              value={projectFormData.name}
            />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiFormRow label="Notes" display="columnCompressed">
            <EuiFieldText
              onChange={e => setProjectFormData({ ...projectFormData, 'notes': e.target.value})}
              placeholder="Project notes"
              value={projectFormData.notes}
            />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiFormRow label="Custom color" isInvalid={isInvalid} error={errors} helpText="Tasks assigned to this Project will be shown in this color." display="columnCompressed">
            <EuiColorPicker 
              placeholder="Auto"
              isClearable={true}
              onChange={setColor} 
              color={color}
              isInvalid={isInvalid} />
          </EuiFormRow>
          <EuiSpacer/>
          <EuiButton fill onClick={createProject}>
            Create Project
          </EuiButton>
        </EuiForm>
      </EuiPanel>
    </div>
  );
}  

export function EditProjectModal({editProject,setEditProject,projects,setProjects,events,setEvents}) {
  const [editProjectFormData,setEditProjectFormData] = useState({});
  const [error,setError] = useState("");
  const [color, setColor] = useState();

  useEffect(() => {
    if(editProject) {
      setEditProjectFormData({id: editProject.key, name: editProject.text, notes: editProject.notes, color: editProject.color, tags: editProject.tags});
      setColor(editProject.color);
    }
  },[editProject]);

  async function editProjectFn() {
    setError("");
    if (!editProjectFormData.id) return;
    try {
      if(editProjectFormData.notes === null) {
        editProjectFormData.notes = "";
      }
      if(editProjectFormData.color === null) {
        editProjectFormData.color = "";
      }
      let apiData = await API.graphql({ query: updateProjectFnMutation, variables: { input: {id: editProjectFormData.id, 'name': editProjectFormData.name, 'notes': editProjectFormData.notes, 'color': editProjectFormData.color}} });
      apiData.data.updateProject = apiData.data.updateProjectFn;
      let newProjects = projects.filter(obj => obj.key !== editProjectFormData.id);
      setProjects([...newProjects, {"key":apiData.data.updateProject.id, "value": apiData.data.updateProject.id, "text": apiData.data.updateProject.name, "label": apiData.data.updateProject.name, "notes": apiData.data.updateProject.notes, "color": apiData.data.updateProject.color, "goalid": apiData.data.updateProject.goalID, "tags": apiData.data.updateProject.tags, "goal":apiData.data.updateProject.goal, "hidden":apiData.data.updateProject.hidden}]);
      setEditProjectFormData({});
      let filteredEvents = events.filter(obj => obj.task.projectID !== editProjectFormData.id);
      let editEvents = events.filter(obj => obj.task.projectID === editProjectFormData.id);
      let updatedEvents = editEvents.map((elem,i)=>({...elem, task: {...elem.task, project: apiData.data.createProject}}));
      let combinedEvents = ([...updatedEvents,...filteredEvents]);
      setEvents(combinedEvents);
      setEditProject(null);
    } catch(e) {
      setError("Error editing Project.");
    }
  }

  return (
    <>
      {editProject &&
        <EuiModal
          style={{ width: 400 }}
          onClose={() => setEditProject(null)}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1><EuiAvatar name="Edit" type="space" iconType="plus" size="m" color="#69707D" />&nbsp;Edit Project</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
              <p>
                {error}
              </p>
            </EuiCallOut><EuiSpacer size='s' /></> }
            <EuiForm>
              <EuiFormRow label="Name">
                <EuiFieldText
                  onChange={e => setEditProjectFormData({ ...editProjectFormData, 'name': e.target.value})}
                  placeholder="Project name"
                  value={editProjectFormData.name}
                />
              </EuiFormRow>
              <EuiSpacer/>
              <EuiFormRow label="Notes">
                <EuiFieldText
                  onChange={e => setEditProjectFormData({ ...editProjectFormData, 'notes': e.target.value})}
                  placeholder="Project notes"
                  value={editProjectFormData.notes}
                />
              </EuiFormRow>
              <EuiSpacer/>
              <EuiFormRow label="Tags">
                <TagSelect editProject={editProject} editProjectFormData={editProjectFormData} projects={projects} setProjects={setProjects}/>
              </EuiFormRow>
              <EuiSpacer/>
              <EuiFormRow label="Custom color"  helpText="Tasks assigned to this Project will be shown in this color.">
                <EuiColorPicker 
                  placeholder="Auto"
                  isClearable={true}
                  onChange={e=>{
                    setColor(e);
                    setEditProjectFormData({ ...editProjectFormData, 'color': e});
                  }} 
                  color={color} />
              </EuiFormRow>
            </EuiForm>
            <EuiSpacer/>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={() => {
              setEditProject(null);
            }}>Cancel</EuiButtonEmpty>

            <EuiButton 
              color="text"
              fill
              onClick={() => {
                editProjectFn();
              }}
            >
              Save
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      }
    </>
  );
}

export function DeleteProjectModal({userData,deleteProject,setDeleteProject,projects,setProjects,events,setEvents,defaultProjectsMap}) {
  const [newProject,setNewProject] = useState({});
  const [selectionMade,setSelectionMade] = useState("");
  const filteredProjects = projects.filter(obj => obj.key !== deleteProject);

  async function deleteProjectFn() {
    if (!newProject.projectID) return;
    const newProjectData =  await API.graphql({ query: getProject, variables: { id: newProject.projectID } });
    let filteredEvents = events.filter(obj => obj.task.projectID !== deleteProject);
    let deleteEvents = events.filter(obj => obj.task.projectID === deleteProject);
    let updatedEvents = deleteEvents.map((elem,i)=>({...elem, task: {...elem.task, projectID: newProject.projectID, project: newProjectData.data.getProject}}));
    let combinedEvents = ([...updatedEvents,...filteredEvents]);
    setEvents(combinedEvents);
    let updatedProjects = projects.filter(obj => obj.key !== deleteProject);
    setProjects([...updatedProjects]);
    setNewProject({});
    API.graphql({ query: deleteProjectJobMutation, variables: { oldProjectID: deleteProject, newProjectID: newProject.projectID} }).catch((error)=>{});
  }

  return (
    <>
      {deleteProject &&
        <EuiModal
          style={{ width: 400 }}
          onClose={() => {
            setSelectionMade("");
            setDeleteProject(null);
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1><EuiAvatar name="Delete" type="space" iconType="plus" size="m" color="#BD271E" />&nbsp;Delete Project</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiCallOut title="Note" color="warning" iconType="help">
              <p>
                Deleting a Project will require selecting a new Project for all the tasks that are currently associated with the Project you want to delete.
              </p>
            </EuiCallOut>
            <EuiForm component="form">
              <EuiFormRow label="Project" helpText="Re-select a Project if the button is not enabled.">
                <ProjectSelect taskFormData={newProject} userData={userData} projects={filteredProjects} setProjects={setProjects} disableCreate={true} noInitial={true} setSelectionMade={setSelectionMade}/>
              </EuiFormRow>
            </EuiForm>
            <EuiSpacer/>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={() => {
              setSelectionMade("");
              setDeleteProject(null);
            }}>Cancel</EuiButtonEmpty>

            <EuiButton 
              color="danger"
              fill
              isDisabled={!selectionMade}
              onClick={() => {
                setSelectionMade("");
                deleteProjectFn();
                setDeleteProject(null);
              }}
            >
              Delete Project
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      }
    </>
  );
}

export function ProjectSelect({userData,taskFormData, projects, setProjects, buttonColor, disableCreate, noInitial, teamID, teamCalendarID, allowEdit, allowDelete, selectionMade, setSelectionMade, reRender, tourActions, tourReducerState, activeCalendar}) {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectionValue, setSelectionValue] = useState(null);
  const [editProject, setEditProject] = useState(null);
  const [deleteProject,setDeleteProject] = useState(null);
  const [events,setEvents] = useState([]);
  const [projectsWithTags,setProjectsWithTags] = useState(projects);

  async function setProjectSelection(projectObj) {
    let projectParse = projectObj.split(":");
    if(projectParse[1]) {
      taskFormData.projectTag = projectParse[1];
    } else {
      taskFormData.projectTag = "";
    }
    taskFormData.projectID = projectParse[0];
    taskFormData.projectName = (projectsWithTags.find( ({ key }) => key === projectParse[0])).text;
    setSelectionValue(projectObj);
  }

  useEffect(()=>{
    if(taskFormData.projectID) {
      let projectID = taskFormData.projectID;
      if(taskFormData.projectTag) {
        projectID=projectID+":"+taskFormData.projectTag;
      }
      if (projectsWithTags.filter(e => e.value === projectID).length > 0) {
        setSelectionValue(projectID);
      } else {
        if(projects.find((obj)=>obj.value === taskFormData.projectID)) {
          setSelectionValue(taskFormData.projectID);
        }
      }
    }
  },[projectsWithTags,reRender]);

  /*
  useEffect(()=> {
    if(projectsWithTags.length && !noInitial) {
      setProjectSelection(projectsWithTags[0].key);
    }
  },[projectsWithTags]);
  */

  useEffect(()=> {
    if(setSelectionMade) {
      if(taskFormData.categories) {
        setSelectionMade(taskFormData.categories[0]+"^"+taskFormData.projectID+"^"+taskFormData.projectTag);
      } else {
        setSelectionMade(taskFormData.name+"^"+taskFormData.projectID+"^"+taskFormData.projectTag);
      }
    }
  },[selectionValue]);
  
  useEffect(()=>{
    let setProjects = [];
    for(let i in projects) {
      setProjects.push(projects[i]);
      if(projects[i].tags) {
        for(let j in projects[i].tags) {
          let tagValue = projects[i].tags[j];
          let tagObject = {
            "key": projects[i].key+":"+tagValue,
            "value": projects[i].value+":"+tagValue,
            "text": "- "+tagValue,
            "label": "- "+tagValue,
            "projectkey": projects[i].key,
            "tagvalue": tagValue,
            "goal": projects[i].goal
          };
          setProjects.push(tagObject);
        }
      }
    }
    setProjectsWithTags(setProjects);
  },[projects])
  
  return (
    <>
      <EuiFlexGroup style={{maxWidth: 250}}>
        <EuiFlexItem style={{minWidth: 180}}>
          <EuiSelect
            placeholder='Associate Project'
            options={projectsWithTags}
            value={selectionValue}
            hasNoInitialSelection={noInitial}
            onChange={(e) => {
              setProjectSelection(e.target.value);
              Analytics.record({ 
                name: 'associateProject'
              });
              if(tourReducerState) {
                if(tourReducerState.currentTourStep === 2) {
                  tourActions.incrementStep();
                }
              }
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <div>
          {(!disableCreate)  && <EuiPopover
            button={<EuiButtonIcon aria-label="Add Project" iconType="listAdd" display="fill" size="m" color={buttonColor}
              onClick={()=>{
                if(!openPopup) {
                  setOpenPopup(true);
                } else {
                  setOpenPopup(false);
                }
              }}
            />}
            isOpen={openPopup}
            closePopover={()=>{setOpenPopup(false)}}
          >
            <CreateProject userData={userData} projects={projects} setProjects={setProjects} doneCallback={setOpenPopup} doneCallbackParam={false} setSelectionValue={setSelectionValue} taskFormData={taskFormData} teamID={teamID} teamCalendarID={teamCalendarID} activeCalendar={activeCalendar}/>
          </EuiPopover>}
        {allowEdit &&
          <span>
          &nbsp;
          <EuiButtonIcon 
            color="text"
            aria-label="Edit"
            iconType="documentEdit"
            size="m"
            display="fill"
            onClick={()=>{
              let filteredProjects = projects.filter(obj => obj.key === selectionValue);
              if(filteredProjects[0]) {
                setEditProject(filteredProjects[0]);
              } else {
                setEditProject(projects[0]);
              }
            }}
          />
          </span>
        }
        {allowDelete && 
          <span>
          &nbsp;
          <EuiButtonIcon 
            color="danger"
            aria-label="Delete"
            iconType="trash"
            size="m"
            display="fill"
            onClick={()=>{
              let filteredProjects = projects.filter(obj => obj.key === selectionValue);
              if(filteredProjects[0]) {
                setDeleteProject(filteredProjects[0].key);
              } else {
                setDeleteProject(projects[0].key);
              }
            }}
          />
          </span>
        }
        </div>
        </EuiFlexItem>
      </EuiFlexGroup>
      <DeleteProjectModal userData={userData} deleteProject={deleteProject} setDeleteProject={setDeleteProject} projects={projects} setProjects={setProjects} events={events} setEvents={setEvents} defaultProjectsMap={[]}/>
      <EditProjectModal userData={userData} editProject={editProject} setEditProject={setEditProject} projects={projects} setProjects={setProjects} events={events} setEvents={setEvents}/>
    </>
  );
}

export function GoalContextMenu({text, hidden, setEditGoal, setDeleteGoal, updateGoalHiddenAttribute, goalObj}) {
  const [openGoalMenu,setOpenGoalMenu] = useState(false);

  const panels = [
    {
      id: 0,
      title: 'Goal: '+text+' actions',
      items: [
        {
          name: 'Edit',
          icon: <EuiIcon type="documentEdit" size="m" />,
          onClick: () => {
            setEditGoal(goalObj);
            setOpenGoalMenu(false);
          },
        },
        {
          name: 'Delete',
          icon: <EuiIcon type="trash" size="m" />,
          onClick: () => {
            setDeleteGoal(goalObj.key);
            setOpenGoalMenu(false);
          },
        },
        {
          name: hidden ? 'Unhide' : 'Hide',
          icon: hidden ? <EuiIcon type="eye" size="m" /> : <EuiIcon type="eyeClosed" size="m" />,
          onClick: () => {
            if(hidden) {
              updateGoalHiddenAttribute(goalObj.key, false);
            } else {
              updateGoalHiddenAttribute(goalObj.key, true);
            }
            setOpenGoalMenu(false);
          },
        },
      ],
    }
  ]

  return (
    <>
      <EuiPopover
        button={<EuiButtonIcon
          size="s"
          iconType="menu"
          onClick={()=>{
            setOpenGoalMenu(true);
          }}
        />}
        isOpen={openGoalMenu}
        closePopover={() => {
          setOpenGoalMenu(false);
        }}
        panelPaddingSize="none"
        withTitle
        anchorPosition="downLeft">
          <EuiContextMenu initialPanelId={0} panels={panels} />
      </EuiPopover>
    </>
  );
}

export function ProjectContextMenu({text, hidden, setEditProject, setDeleteProject, updateProjectHiddenAttribute, projectObj}) {
  const [openProjectMenu,setOpenProjectMenu] = useState(false);

  const panels = [
    {
      id: 0,
      title: 'Project: '+text+' actions',
      items: [
        {
          name: 'Edit',
          icon: <EuiIcon type="documentEdit" size="m" />,
          onClick: () => {
            setEditProject(projectObj);
            setOpenProjectMenu(false);
          },
        },
        {
          name: 'Delete',
          icon: <EuiIcon type="trash" size="m" />,
          onClick: () => {
            setDeleteProject(projectObj.key);
            setOpenProjectMenu(false);
          },
        },
        {
          name: hidden ? 'Unhide' : 'Hide',
          icon: hidden ? <EuiIcon type="eye" size="m" /> : <EuiIcon type="eyeClosed" size="m" />,
          onClick: () => {
            if(hidden) {
              updateProjectHiddenAttribute(projectObj.key, false);
            } else {
              updateProjectHiddenAttribute(projectObj.key, true);
            }
            setOpenProjectMenu(false);
          },
        },
      ],
    }
  ]

  return (
    <>
      <EuiPopover
        button={<EuiButtonIcon
          size="s"
          iconType="menu"
          onClick={()=>{
            setOpenProjectMenu(true);
          }}
        />}
        isOpen={openProjectMenu}
        closePopover={() => {
          setOpenProjectMenu(false);
        }}
        panelPaddingSize="none"
        withTitle
        anchorPosition="downLeft">
          <EuiContextMenu initialPanelId={0} panels={panels} />
      </EuiPopover>
    </>
  );
}

export function ProjectManagement({userData,projects,setProjects,goals,setGoals,events,setEvents,defaultProjectsMap,userSettings,setUserSettings,euiTourSteps,tourActions,tourReducerState,activeCalendar}) {
  const filteredProjects = projects.filter(obj => !defaultProjects[obj.key] && !(obj.team === "true"));
  const notAssignedProjects = filteredProjects.filter(obj => !obj.goalid);
  const [deleteProject, setDeleteProject] = useState(null);
  const [editProject, setEditProject] = useState(null);
  const [deleteGoal, setDeleteGoal] = useState(null);
  const [editGoal, setEditGoal] = useState(null);
  const [error,setError] = useState("");
  const { euiTheme } = useEuiTheme();

  const updateProjectHiddenAttribute = async (key, value) => {
    let apiData = await API.graphql({ query: updateProjectFnMutation, variables: { input: {id: key, 'hidden': value}} });
    apiData.data.updateProject = apiData.data.updateProjectFn;
    let newProjects = projects.filter(obj => obj.key !== key);
    if(value === false || userSettings.hideprojects ) {
      newProjects = [...newProjects, {"key":apiData.data.updateProject.id, "value": apiData.data.updateProject.id, "text": apiData.data.updateProject.name, "label": apiData.data.updateProject.name, "notes": apiData.data.updateProject.notes, "color": apiData.data.updateProject.color, "goalid": apiData.data.updateProject.goalID, "tags": apiData.data.updateProject.tags, "goal":apiData.data.updateProject.goal, "hidden":apiData.data.updateProject.hidden}];
    }

    newProjects = newProjects.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    setProjects(newProjects);
  };

  const updateGoalHiddenAttribute = async (key, value) => {
    let apiData = await API.graphql({ query: updateGoalFnMutation, variables: { input: {id: key, 'hidden': value}} });
    apiData.data.updateGoal = apiData.data.updateGoalFn;
    let newGoals = goals.filter(obj => obj.key !== key);
    if(value === false || userSettings.hideprojects ) {
      newGoals = [...newGoals, {"key":apiData.data.updateGoal.id, "value": apiData.data.updateGoal.id, "text": apiData.data.updateGoal.name, "label": apiData.data.updateGoal.name, "notes": apiData.data.updateGoal.notes, "hidden": apiData.data.updateGoal.hidden, "order": apiData.data.updateGoal.order}];
    }

    newGoals = newGoals.sort((a, b) => { 
      return ((a.order > b.order) && a.text.localeCompare(b.text)) ? 1 : -1;
    });

    setGoals(newGoals);
  };
  
  const onDragEnd = async ({ source, destination }) => {
    setError("");
    let projectList = {};
    for(let i in filteredProjects) {
      if(!filteredProjects[i].goalid) {
        if(!projectList['Unassigned']) {
          projectList['Unassigned'] = [];
        }
        projectList['Unassigned'].push(filteredProjects[i]);
      } else {
        if(!projectList[filteredProjects[i].goalid]) {
          projectList[filteredProjects[i].goalid] = [];
        }
        projectList[filteredProjects[i].goalid].push(filteredProjects[i]);
      }
    }
    if (source && destination) {
      if(source.droppableId === destination.droppableId && source.droppableId === "Goals") {
        const items = euiDragDropReorder(goals, source.index, destination.index);
        setGoals(items);
        for(let i in items) {
          items[i].order = i;
          API.graphql({ query: updateGoalFnMutation, variables: { input: {id: items[i].key, 'order':items[i].order}} }).catch((error)=>{});
        }
      } else if(destination.droppableId === "Unassigned") {
        try {
          let apiData = await API.graphql({ query: updateProjectFnMutation, variables: { input: {id: projectList[source.droppableId][source.index].key, 'goalID': ''}} });
          apiData.data.updateProject = apiData.data.updateProjectFn;
          let newProjectObject = [{"key":apiData.data.updateProject.id, "value": apiData.data.updateProject.id, "text": apiData.data.updateProject.name, "label": apiData.data.updateProject.name, "notes": apiData.data.updateProject.notes, "color": apiData.data.updateProject.color, "goalid": '', "hidden": apiData.data.updateProject.hidden}];
          let newProjects = projects.map(obj => newProjectObject.find(o => o.key === obj.key) || obj);
          setProjects(newProjects);
        } catch(e) {
          setError("Error updating Project.");
        }
      } else {
        try {
          let apiData = await API.graphql({ query: updateProjectFnMutation, variables: { input: {id: projectList[source.droppableId][source.index].key, 'goalID': destination.droppableId}} });
          apiData.data.updateProject = apiData.data.updateProjectFn;
          let findGoal = goals.filter(obj => obj.key === destination.droppableId);
          let newProjectObject = [{"key":apiData.data.updateProject.id, "value": apiData.data.updateProject.id, "text": apiData.data.updateProject.name, "label": apiData.data.updateProject.name, "notes": apiData.data.updateProject.notes, "color": apiData.data.updateProject.color, "hidden": apiData.data.updateProject.hidden, "goal": {'name':findGoal[0].text}, "goalid": destination.droppableId}];
          let newProjects = projects.map(obj => newProjectObject.find(o => o.key === obj.key) || obj);
          setProjects(newProjects);
        } catch(e) {
          setError("Error updating Project.");
        }
        if(tourReducerState.currentTourStep === 8) {
          tourActions.incrementStep();
        }
      }
    }
  };

  return(
    <>
      <EuiPageTemplate 
          template="empty"
          restrictWidth="100%">
          {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
            <p>
              {error}
            </p>
          </EuiCallOut><EuiSpacer size='s' /></> }  
          <EuiFlexGroup>
            <EuiFlexItem 
              css={css`
                padding: calc(${euiTheme.size.m});
                ${logicals['max-width']}: 325px;
              `}>
              <div>
                <EuiPanel paddingSize="m" grow={false}>
                  <EuiText grow={false} textAlign='center'>
                    <h3><EuiAvatar name="projectVisibility" type="space" iconType="eye" size="m" color="#0071c2" />&nbsp;Visibility</h3>
                  </EuiText>
                  <EuiSpacer size='m' />
                  <EuiSwitch 
                    label="Show hidden goals/projects"
                    checked={userSettings.hideprojects}
                    onChange={(e) => {
                      setUserSettings({ ...userSettings, 'hideprojects': e.target.checked});
                      API.graphql({ query: updateUserFnMutation, variables: { input: { 'hideprojects':e.target.checked}} }).catch((error)=>{});
                    }}
                  />
                  <EuiSpacer size='s' />
                  <EuiSwitch 
                    label="Show default projects"
                    checked={!userSettings.hidedefaultprojects}
                    onChange={(e) => {
                      setUserSettings({ ...userSettings, 'hidedefaultprojects': !e.target.checked});
                      API.graphql({ query: updateUserFnMutation, variables: { input: { 'hidedefaultprojects':!e.target.checked}} }).catch((error)=>{});
                    }}
                  />
                </EuiPanel>
              </div>
              <EuiSpacer size='s' />
              <CreateProject userData={userData} projects={projects} setProjects={setProjects} activeCalendar={activeCalendar}/>
              <EuiSpacer/>
              <EuiTourStep
                {...euiTourSteps[6]}
              >
                <CreateGoal userData={userData} goals={goals} setGoals={setGoals} tourActions={tourActions} tourReducerState={tourReducerState} activeCalendar={activeCalendar}/>
              </EuiTourStep>
            </EuiFlexItem>
            <EuiFlexItem css={css`
              padding: calc(${euiTheme.size.m});
              ${logicals['width']}: 850px;
            `}>
              <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiFlexGroup>
                  <EuiFlexItem css={css`
                    ${logicals['width']}: 60%;
                  `}>
                    <EuiPanel>
                      <EuiText size='m'><h3>Goals</h3></EuiText>
                        <EuiDroppable droppableId="Goals" spacing="m" type="Goal">
                          {goals.map(({ key, text, notes, hidden }, idx) => (
                            <>
                              <EuiDraggable spacing="m" key={key} index={idx} draggableId={key} type="Goal">
                                <EuiPanel grow={true}>
                                <EuiFlexGroup>
                                    <EuiFlexItem grow={9}></EuiFlexItem>
                                    <EuiFlexItem grow={1}>
                                      <GoalContextMenu text={text} hidden={hidden} setEditGoal={setEditGoal} setDeleteGoal={setDeleteGoal} updateGoalHiddenAttribute={updateGoalHiddenAttribute} goalObj={goals[idx]}/>
                                    </EuiFlexItem>
                                  </EuiFlexGroup>
                                  <EuiFlexGroup gutterSize="none" alignItems="center">
                                    <EuiFlexItem grow={2}><EuiStat title={idx+1} titleColor={ hidden ? "subdued":"primary" } description="Priority" titleSize ='m' /></EuiFlexItem>
                                    <EuiFlexItem grow={8}> 
                                      <EuiStat title={text.substring(0,20)} titleColor={ hidden ? "subdued":"primary" } description="Name" titleSize ='m'/>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={1}>
                                      { hidden && <EuiIcon type="eyeClosed" size="m" /> }
                                    </EuiFlexItem>
                                  </EuiFlexGroup>
                                  <EuiSpacer size="s"/>
                                  <EuiText size='xs' css={ css`max-width: 325px; overflow-wrap: break-word;` }>{notes ? notes : ""}</EuiText>
                                  <EuiFlexGroup>
                                    <EuiFlexItem>
                                      <EuiText size='m'><h4>Projects</h4></EuiText>
                                      <EuiDroppable droppableId={key} spacing="m" style={{ minHeight: 50 }} type="Project">
                                        {filteredProjects.filter(obj => obj.goalid === key).map(({ key, text, notes, color, goal, goalid, hidden }, projectIdx) => (
                                          <>
                                            <EuiDraggable spacing="m" key={key} index={projectIdx} draggableId={key} type="Project">
                                              <EuiPanel grow={false}>
                                                <EuiFlexGroup gutterSize="none" alignItems="center">
                                                  <EuiFlexItem grow={8}>
                                                    <EuiHealth color={color} textSize={hidden ? "xs" : "s"}>
                                                      {text.substring(0,20)} 
                                                    </EuiHealth>
                                                  </EuiFlexItem>
                                                  <EuiFlexItem grow={1}>
                                                  { hidden && <EuiIcon type="eyeClosed" size="m" /> }
                                                  </EuiFlexItem>
                                                  <EuiFlexItem grow={1}>
                                                    <ProjectContextMenu text={text} hidden={hidden} setEditProject={setEditProject} setDeleteProject={setDeleteProject} updateProjectHiddenAttribute={updateProjectHiddenAttribute} projectObj={filteredProjects.filter(obj => obj.goalid === goalid)[projectIdx]}/>
                                                  </EuiFlexItem>
                                                </EuiFlexGroup>
                                                <EuiSpacer size="s"/>
                                                <EuiText size='xs' css={ css`max-width: 325px; overflow-wrap: break-word;` }>{notes ? notes : ""}</EuiText>
                                              </EuiPanel>
                                            </EuiDraggable>
                                          </>
                                        ))}
                                      </EuiDroppable>
                                    </EuiFlexItem>
                                  </EuiFlexGroup>
                                </EuiPanel>
                              </EuiDraggable>
                            </>
                          ))}
                        </EuiDroppable>
                      </EuiPanel>
                  </EuiFlexItem>
                  <EuiFlexItem css={css`
                    ${logicals['width']}: 40%;
                  `}>
                    <EuiPanel>
                      <EuiText size='m'><h3>Projects not Assigned</h3></EuiText>
                      <EuiDroppable droppableId="Unassigned" spacing="m" type="Project" style={{minWidth: 300, minHeight: 100}}>
                        {notAssignedProjects.map(({ key, text, notes, color, goal, hidden }, idx) => (
                          <>
                            <EuiDraggable spacing="m" key={key} index={idx} draggableId={key} type="Project">
                              <EuiPanel grow={false}>
                                <EuiFlexGroup gutterSize="none" alignItems="center">
                                  <EuiFlexItem grow={8}>
                                    <EuiHealth color={color} textSize={hidden ? "xs" : "s"}>
                                      {text.substring(0,20)} 
                                    </EuiHealth>
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={1}>
                                    { hidden && <EuiIcon type="eyeClosed" size="m" /> }
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={1}>
                                    <ProjectContextMenu text={text} hidden={hidden} setEditProject={setEditProject} setDeleteProject={setDeleteProject} updateProjectHiddenAttribute={updateProjectHiddenAttribute} projectObj={notAssignedProjects[idx]}/>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiSpacer size="s"/>
                                <EuiText size='xs' css={ css`max-width: 325px; overflow-wrap: break-word;` }>{notes ? notes : ""}</EuiText>
                                <EuiSpacer/>
                                <EuiFlexGroup>
                                  <EuiFlexItem grow={false}>
                                    {goal && <EuiBadge color="default">{goal.name}</EuiBadge>}
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </EuiPanel>
                            </EuiDraggable>
                          </>
                        ))}
                      </EuiDroppable>
                      <EuiTourStep
                        {...euiTourSteps[7]}
                      ></EuiTourStep>
                    </EuiPanel>
                  </EuiFlexItem>  
              </EuiFlexGroup>
            </EuiDragDropContext>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageTemplate>
      <DeleteGoalModal deleteGoal={deleteGoal} setDeleteGoal={setDeleteGoal} projects={projects} setProjects={setProjects} goals={goals} setGoals={setGoals}/>
      <EditGoalModal editGoal={editGoal} setEditGoal={setEditGoal} goals={goals} setGoals={setGoals} projects={projects} setProjects={setProjects}/>
      <DeleteProjectModal userData={userData} deleteProject={deleteProject} setDeleteProject={setDeleteProject} projects={projects} setProjects={setProjects} events={events} setEvents={setEvents} defaultProjectsMap={defaultProjectsMap}/>
      <EditProjectModal userData={userData} editProject={editProject} setEditProject={setEditProject} projects={projects} setProjects={setProjects} events={events} setEvents={setEvents}/>
    </>
  );
}